import React, { useEffect, useState } from "react";
import jwt from "jwt-decode";
import client from "../../utils/Client";
import "./style.scss";
import { userLogin, userLogout } from "../../redux/user/userAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import { MenuItem, Select } from "@mui/material";
import RegistrationDone from "./RegistrationDone";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
const Registration = () => {
  const hisroty = useNavigate();
  const [registrationId, setRegistrationId] = useState(null);
  const [userData, setUserData] = useState({
    roll: "",
    password: "",
    dept: "CSE",
    confirmpassword: "",
    batch: "",
    phone: "",
    gmail: "",
    name: "",
  });
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (user) {
      hisroty("/users/" + user.roll);
    }
  }, []);
  const handleSubmit = () => {
    client.post("/auth/registration", { ...userData }).then((res) => {
      if (res.data.status) {
        setRegistrationId(res.data.registration_id);
      } else {
        dispatch(
          open_snackbar({
            message: res.data.message,
            type: "error",
          })
        );
      }
    });
  };

  // console.log(userData);
  if (registrationId !== null)
    return <RegistrationDone registrationId={registrationId} />;
  return (
    <>
      <Header />
      <div className="registration">
        <img
          src="Assets/programming-1.svg"
          alt="downloaded from freepic"
          className="hero-pic"
        />
        <div className="login-midle">
          <div className="Login">
            <div className="login-xy">
              <p className="semi-title">Name : </p>
              <input
                value={userData.name}
                onChange={(e) =>
                  setUserData({ ...userData, name: e.target.value })
                }
                placeholder="Full name"
              />
              <div className="single-line-item">
                <div className="input">
                  <p className="semi-title">Roll : </p>
                  <input
                    value={userData.roll}
                    onChange={(e) =>
                      setUserData({ ...userData, roll: e.target.value })
                    }
                    placeholder="Your Roll number"
                  />
                </div>
                <div className="input">
                  <p className="semi-title">Batch : </p>
                  <input
                    value={userData.batch}
                    onChange={(e) =>
                      setUserData({ ...userData, batch: e.target.value })
                    }
                    placeholder="ex : 26"
                  />
                </div>
              </div>
              <div className="input">
                <p className="semi-title">Department : </p>
                <Select
                  value={userData.dept}
                  onChange={(e) =>
                    setUserData({ ...userData, dept: e.target.value })
                  }
                  defaultValue="CSE"
                  className="input-1"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={"CSE"}>CSE</MenuItem>
                  <MenuItem value={"ECE"}>ECE</MenuItem>
                  <MenuItem value={"BBA"}>BBA</MenuItem>
                  <MenuItem value={"DEPLOMA"}>DEPLOMA</MenuItem>
                </Select>
              </div>

              <div className="single-line-item">
                <div className="input">
                  <p className="semi-title">Phone : </p>
                  <input
                    value={userData.phone}
                    onChange={(e) =>
                      setUserData({ ...userData, phone: e.target.value })
                    }
                    placeholder="017XXXXXXXX"
                  />
                </div>
                <div className="input">
                  <p className="semi-title">Email : </p>
                  <input
                    value={userData.gmail}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        gmail: e.target.value,
                      })
                    }
                    placeholder="example@gmail.com"
                  />
                </div>
              </div>

              <div className="single-line-item">
                <div className="input">
                  <p className="semi-title">Password : </p>
                  <input
                    value={userData.password}
                    onChange={(e) =>
                      setUserData({ ...userData, password: e.target.value })
                    }
                    placeholder="password"
                    type={"password"}
                  />
                </div>
                <div className="input">
                  <p className="semi-title">Confirm Password : </p>
                  <input
                    value={userData.confirmpassword}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        confirmpassword: e.target.value,
                      })
                    }
                    placeholder="confirm password"
                    type={"password"}
                  />
                </div>
              </div>
              <button className="submit-button" onClick={handleSubmit}>
                Request for new account
              </button>
              <div className="extra-tab">
                <Link to={"/login"}>Sign In</Link>
                <span>|</span>
                <Link to={"/forget-password"}>Forget password</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className={"reg-footer"} />
    </>
  );
};

export default Registration;
