import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import CertificateItem from "../../components/CertificateItem/CertificateItem";
import client from "../../utils/Client";

const MyCertificates = () => {
  const roll = useSelector((state) => state.user.roll);
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    client.get("/certificate/user/" + roll).then((res) => {
      console.log(res.data);
      setCertificates([...res.data.certificate]);
    });
  }, []);
  return (
    <div className="my-certificates">
      <div className="title">My Certificates</div>
      {certificates.length === 0 ? (
        <div>You have no certificates</div>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
          className="certificates-xy"
        >
          {certificates.map((item, i) => (
            <CertificateItem item={item} key={i} />
          ))}
        </div>
      )}
    </div>
  );
};

export default MyCertificates;
