import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import client from "../../utils/Client";

const ApproveNewUser = () => {
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [SnackbarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch();
  const [snakbartext, setsnackbartext] = useState({
    type: "error",
    message: "error message",
  });
  let cancelSearch = new AbortController();
  const search = () => {
    if (cancelSearch) cancelSearch.abort();
    cancelSearch = new AbortController();
    client
      .get("/user/unapproveusers/" + searchText, {
        signal: cancelSearch.signal,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setUsers([...res.data.users]);
        }
      })
      .catch((err) => {
        console.log("searching...");
      });
  };
  useEffect(() => {
    search();
  }, [searchText]);
  const approveUser = (id) => {
    dispatch(open_loader());
    client
      .post("/usermanagement/approveuser", { registration_id: users[id].id })
      .then((res) => {
        dispatch(close_loader());
        if (res.data.status) {
          setSnackbarOpen(true);
          setsnackbartext({
            type: "success",
            message: res.data.message,
          });
          search();
        } else {
          setSnackbarOpen(true);
          setsnackbartext({
            type: "error",
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteUser = (id) => {
    client
      .post("/usermanagement/deleteuser", { registration_id: users[id].id })
      .then((res) => {
        if (res.data.status) {
          setSnackbarOpen(true);
          setsnackbartext({
            type: "success",
            message: res.data.message,
          });
          search();
        } else {
          setSnackbarOpen(true);
          setsnackbartext({
            type: "error",
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="approve-new-user">
      <Snackbar
        open={SnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snakbartext.type}
          sx={{ width: "100%" }}
        >
          {snakbartext.message}
        </Alert>
      </Snackbar>
      <div className="header-xy">
        <div className="title">Approve New User</div>
        <div className="search-box">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M33.8 36L20.65 22.85C19.65 23.7167 18.4833 24.3917 17.15 24.875C15.8167 25.3583 14.4 25.6 12.9 25.6C9.3 25.6 6.25 24.35 3.75 21.85C1.25 19.35 0 16.3333 0 12.8C0 9.26667 1.25 6.25 3.75 3.75C6.25 1.25 9.28333 0 12.85 0C16.3833 0 19.3917 1.25 21.875 3.75C24.3583 6.25 25.6 9.26667 25.6 12.8C25.6 14.2333 25.3667 15.6167 24.9 16.95C24.4333 18.2833 23.7333 19.5333 22.8 20.7L36 33.8L33.8 36ZM12.85 22.6C15.55 22.6 17.85 21.6417 19.75 19.725C21.65 17.8083 22.6 15.5 22.6 12.8C22.6 10.1 21.65 7.79167 19.75 5.875C17.85 3.95833 15.55 3 12.85 3C10.1167 3 7.79167 3.95833 5.875 5.875C3.95833 7.79167 3 10.1 3 12.8C3 15.5 3.95833 17.8083 5.875 19.725C7.79167 21.6417 10.1167 22.6 12.85 22.6Z"
              fill="black"
            />
          </svg>

          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="registration id"
          />
        </div>
      </div>
      <div className="all-users">
        {users.map((item, i) => (
          <div key={i} className="single-rank">
            <div className="left-side">
              <div className="team-name">{item.name}</div>
              <div className="team-position">Reg ID: {item.id}</div>
            </div>
            <div className="right-side-x">
              <button onClick={() => deleteUser(i)} className="hexbutton">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.81246 21.6516L14.3867 16.0774L19.9608 21.6516L21.5867 20.0258L16.0125 14.4516L21.5867 8.8774L19.9608 7.2516L14.3867 12.8258L8.81246 7.2516L7.18666 8.8774L12.7608 14.4516L7.18666 20.0258L8.81246 21.6516ZM2.77375 28.3871C2.1544 28.3871 1.61246 28.1548 1.14795 27.6903C0.68343 27.2258 0.451172 26.6839 0.451172 26.0645V2.83869C0.451172 2.21934 0.68343 1.6774 1.14795 1.21289C1.61246 0.748371 2.1544 0.516113 2.77375 0.516113H25.9996C26.6189 0.516113 27.1608 0.748371 27.6254 1.21289C28.0899 1.6774 28.3221 2.21934 28.3221 2.83869V26.0645C28.3221 26.6839 28.0899 27.2258 27.6254 27.6903C27.1608 28.1548 26.6189 28.3871 25.9996 28.3871H2.77375Z"
                    fill="#D83333"
                  />
                </svg>
              </button>
              <button onClick={() => approveUser(i)} className="hexbutton">
                <svg
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.1011 20.6683L23.3123 9.41829L21.6442 7.75019L12.1011 17.3321L7.48471 12.7157L5.81661 14.3838L12.1011 20.6683ZM2.82954 28.4657C2.20885 28.4657 1.66575 28.2329 1.20023 27.7674C0.734712 27.3019 0.501953 26.7588 0.501953 26.1381V2.86225C0.501953 2.24156 0.734712 1.69846 1.20023 1.23294C1.66575 0.767427 2.20885 0.534668 2.82954 0.534668H26.1054C26.7261 0.534668 27.2692 0.767427 27.7347 1.23294C28.2002 1.69846 28.433 2.24156 28.433 2.86225V26.1381C28.433 26.7588 28.2002 27.3019 27.7347 27.7674C27.2692 28.2329 26.7261 28.4657 26.1054 28.4657H2.82954Z"
                    fill="#49BA54"
                  />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApproveNewUser;
