import { Snackbar, Alert } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import { userLogin } from "../../redux/user/userAction";
import client from "../../utils/Client";
const MyProfile = () => {
  const roll = useSelector((state) => state.user).roll;
  const [user, setUser] = useState({});
  const [picForUpload, setPicForUpload] = useState(null);
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [tempProfilePic, setTempProfilePic] = useState(null);
  const [SnackbarOpen, setSnackbarOpen] = useState(false);
  const [snakbartext, setsnackbartext] = useState({
    type: "error",
    message: "error message",
  });
  const update_profile = () => {
    dispatch(open_loader());
    client.get("/user/details/" + roll).then((res) => {
      if (res.data.status) {
        dispatch(close_loader());
        setUser({ ...res.data.user });
        dispatch(userLogin(res.data.user));
      }
    });
  };
  useEffect(() => {
    update_profile();
  }, []);
  if (!user && user.roll) return <></>;
  const handleProfilePicUpload = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };
  const updateProfilePicture = () => {
    if (picForUpload === null) {
      setSnackbarOpen(true);
      setsnackbartext({
        message: "file not uploaded",
        type: "error",
      });
      return;
    }

    dispatch(open_loader());
    const formData = new FormData();
    formData.append("file", picForUpload);
    client
      .post("/user/change-profile-pic", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch(close_loader());
        if (res.data.status) {
          setPicForUpload(null);
          setTempProfilePic(null);
          setsnackbartext({
            message: "profile picture changed!",
            type: "success",
          });
          update_profile();
        } else {
          setsnackbartext({
            message: "something went wrong!",
            type: "error",
          });
        }
        update_profile();
        setSnackbarOpen(true);
      });
  };
  return (
    <div className="my-profile">
      <Snackbar
        open={SnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snakbartext.type}
          sx={{ width: "100%" }}
        >
          {snakbartext.message}
        </Alert>
      </Snackbar>
      <div className="title">My Profile</div>
      <div className="profile-head">
        <div className="profile-picture-xyz">
          <img
            src={picForUpload ? tempProfilePic : user.profilePic}
            alt="i-mage"
            className="profile-pic"
          />
          <div onClick={handleProfilePicUpload} className="change-axx">
            change
          </div>
        </div>
        <input
          ref={inputRef}
          style={{ display: "none" }}
          type="file"
          onChange={(e) => {
            setTempProfilePic(URL.createObjectURL(e.target.files[0]));
            setPicForUpload(e.target.files[0]);
          }}
        />
        <div className="upload-xy">
          {picForUpload === null ? (
            <button
              style={{ display: "none" }}
              onClick={handleProfilePicUpload}
            >
              Change Photo
            </button>
          ) : (
            <button onClick={updateProfilePicture} className="success-color">
              Save
            </button>
          )}
          {picForUpload && (
            <button onClick={() => setPicForUpload(null)}>Remove</button>
          )}
        </div>
      </div>
      <p className="semi-title">Name</p>
      <input
        style={{
          width: "75%",
        }}
        className="input-1"
        readOnly
        value={user.name}
      />
      <div className="duel-item w-75">
        <div className="duel-single-item">
          <div className="semi-title">Roll</div>
          <input
            style={{ width: "100%" }}
            type="text"
            value={user.roll}
            className="input-1"
            readOnly
          />
        </div>
        <div className="duel-single-item">
          <div className="semi-title">Batch</div>
          <input
            style={{ width: "100%" }}
            type="text"
            value={user.batch}
            className="input-1"
            readOnly
          />
        </div>
      </div>
      <div className="duel-item w-75">
        <div className="duel-single-item">
          <div className="semi-title">Department</div>
          <input
            style={{ width: "100%" }}
            type="text"
            value={user.dept}
            className="input-1"
            readOnly
          />
        </div>
        <div className="duel-single-item">
          <div className="semi-title">Position</div>
          <input
            style={{ width: "100%" }}
            type="text"
            value={user.position}
            className="input-1"
            readOnly
          />
        </div>
      </div>
      <div className="duel-item w-75">
        <div className="duel-single-item">
          <div className="semi-title">Phone</div>
          <input
            style={{ width: "100%" }}
            type="text"
            value={user.phone}
            className="input-1"
            readOnly
          />
        </div>
        <div className="duel-single-item">
          <div className="semi-title">Gmail</div>
          <input
            style={{ width: "100%" }}
            type="text"
            value={user.gmail}
            className="input-1"
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
