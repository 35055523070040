import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import client from "../../utils/Client";
import splitintotwo from "../../utils/splitintotwo";
import { CgFacebook } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { FiLinkedin } from "react-icons/fi";
const Team = () => {
  const navigate = useNavigate();
  const [pcistTeam, setPcistTeam] = useState({
    gs: [],
    js: [],
    em: [],
    os: [],
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(open_loader());
    client.get("/pcist-team").then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        setPcistTeam({
          gs: [...res.data.gs],
          js: [...res.data.js],
          em: [...res.data.em],
          os: [...res.data.os],
        });
      }
    });
  }, []);
  return (
    <div className="team-section">
      <div className="title">Team of pcIST</div>
      {/* <div className="back-image">
        <img src="Assets/lines.svg" alt="" />
      </div> */}

      <div className="butors">
        {pcistTeam.gs.map((item, i) => (
          <div key={i} className="contributor">
            <div className="profile-pic">
              <img src={item.profilePic} alt="" />
            </div>
            <Link to={`/users/${item.roll}`} className="name">
              {splitintotwo(item.name)}
            </Link>
            <div className="tech">{item.position}</div>
            <div className="social">
              <a target={"_blank"} href={item.fb}>
                <CgFacebook />
              </a>
              <a target={"_blank"} href={item.gh}>
                <BsGithub />
              </a>
              <a target={"_blank"} href={item.li}>
                <FiLinkedin />
              </a>
            </div>
          </div>
        ))}
        {pcistTeam.js.map((item, i) => (
          <div key={i} className="contributor">
            <div className="profile-pic">
              <img src={item.profilePic} alt="" />
            </div>
            <Link to={`/users/${item.roll}`} className="name">
              {splitintotwo(item.name)}
            </Link>
            <div className="tech">{item.position}</div>
            <div className="social">
              <a target={"_blank"} href={item.fb}>
                <CgFacebook />
              </a>
              <a target={"_blank"} href={item.gh}>
                <BsGithub />
              </a>
              <a target={"_blank"} href={item.li}>
                <FiLinkedin />
              </a>
            </div>
          </div>
        ))}
        {pcistTeam.os.map((item, i) => (
          <div key={i} className="contributor">
            <div className="profile-pic">
              <img src={item.profilePic} alt="" />
            </div>
            <Link to={`/users/${item.roll}`} className="name">
              {splitintotwo(item.name)}
            </Link>
            <div className="tech">{item.position}</div>
            <div className="social">
              <a target={"_blank"} href={item.fb}>
                <CgFacebook />
              </a>
              <a target={"_blank"} href={item.gh}>
                <BsGithub />
              </a>
              <a target={"_blank"} href={item.li}>
                <FiLinkedin />
              </a>
            </div>
          </div>
        ))}
        {pcistTeam.em.map((item, i) => (
          <div key={i} className="contributor">
            <div className="profile-pic">
              <img src={item.profilePic} alt="" />
            </div>
            <Link to={`/users/${item.roll}`} className="name">
              {splitintotwo(item.name)}
            </Link>
            <div className="tech">{item.position}</div>
            <div className="social">
              <a target={"_blank"} href={item.fb}>
                <CgFacebook />
              </a>
              <a target={"_blank"} href={item.gh}>
                <BsGithub />
              </a>
              <a target={"_blank"} href={item.li}>
                <FiLinkedin />
              </a>
            </div>
          </div>
        ))}
      </div>
      {/* 
      <div className="js team">
        {pcistTeam.js.map((item, key) => (
          <div key={key} className="js-1">
            <div className="outer-box-image">
              <img src={item.profilePic} alt="" />
            </div>
            <div
              onClick={() => navigate("/users/" + item.roll)}
              className="name"
            >
              {splitintotwo(item.name)}
            </div>
            <div className="position">Joint Secretary</div>
          </div>
        ))}
      </div>
      <div className="em team">
        {pcistTeam.em.map((item, key) => (
          <div key={key} className="js-1">
            <div className="team-xy">
              <div className="outer-box-image">
                <img src={item.profilePic} alt="" />
              </div>
              <div
                onClick={() => navigate("/users/" + item.roll)}
                className="name"
              >
                {splitintotwo(item.name)}
              </div>
              <div className="position">Executive member</div>
            </div>
          </div>
        ))} */}
      {/* </div> */}
    </div>
  );
};

export default Team;
