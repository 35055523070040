import React from "react";
import "./style.scss";
import { GiRibbonMedal } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
const CertificateItem = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/certificate/" + item.cid)}
      className="certificate-item"
    >
      <div className="medel">
        <GiRibbonMedal />
      </div>
      <p>{item.cname}</p>
    </div>
  );
};

export default CertificateItem;
