import React from "react";
import { Link} from "react-router-dom";
import options from "./options";
const Nav = ({ activeTab }) => {
  return (
    <div
      style={{
        backgroundImage: `url("Assets/black-lines.jpg")`,
      }}
      className="admin-nav"
    >
      {options.map((item, id) => (
        <Link key={id} to={window.location.pathname + "?Tab=" + item.tab}>
          <div
            className={`single-item${item.tab === activeTab ? " active" : ""}`}
          >
            {item.icon}
            <div className="item-text">{item.text} </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Nav;
