import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FileUpload from "../FileUpload/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_LOADER, OPEN_LOADER } from "../../redux/types";
import {
  open_snackbar,
  close_snackbar,
} from "../../redux/SnackBar/SnackBarActions";
import generateCertificate from "../../utils/htmltoimg";
import client from "../../utils/Client";
const CreateCertificate = ({ open, setOpen }) => {
  const [details, setDetails] = useState({
    file: null,
    x: 0,
    y: 0,
    nameColor: "",
    imgHeight: 0,
    imgWidth: 0,
    fontSize: 20,
    name: "",
    details: "",
    fontFamily: "kalam-bold-normal",
  });
  const user = useSelector((state) => state.user);
  const previewref = useRef();
  const previewimgref = useRef();
  const nameref = useRef();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (details.file) {
      dispatch({ type: OPEN_LOADER });
      let img = new Image();
      img.onload = function () {
        setDetails({
          ...details,
          imgHeight: this.height,
          imgWidth: this.width,
        });
        dispatch({ type: CLOSE_LOADER });
      };
      img.onerror = function () {
        alert("not a valid file: " + details.file.type);
        dispatch({ type: CLOSE_LOADER });
      };
      img.src = URL.createObjectURL(details.file);
    }
  }, [details.file]);
  useEffect(() => {
    if (nameref.current && previewref.current) {
      let pheight = previewref.current.clientHeight;
      let pwidth = previewref.current.clientWidth;
      let x = (details.x / details.imgWidth) * pwidth;
      let y = (details.y / details.imgHeight) * pheight;
      nameref.current.style.left = x + "px";
      nameref.current.style.top = y + "px";
    }

    // generateCertificate(details, user.name, false);
  }, [details]);
  const handleSave = () => {
    dispatch({ type: OPEN_LOADER });
    let formdata = new FormData();
    Object.keys(details).forEach((item) => {
      formdata.append(item, details[item]);
    });
    client.post("/certificate/addnew", formdata).then((res) => {
      dispatch({ type: CLOSE_LOADER });
      if (res.data.status) {
        handleClose();
      }
      dispatch(
        open_snackbar({
          type: res.data.status ? "success" : "error",
          message: res.data.message,
        })
      );
    });
  };
  return (
    <div className="create-certificate">
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="create-certificate-modal"
      >
        <DialogTitle id="scroll-dialog-title">
          Add Certificate Details
        </DialogTitle>
        <DialogContent dividers={true}>
          <p className="p">Certificate Name:</p>
          <input
            style={{
              width: "100%",
              height: 35,
            }}
            value={details.name}
            onChange={(e) => setDetails({ ...details, name: e.target.value })}
          />
          <p className="p">Details: </p>
          <textarea
            style={{
              width: "100%",
              height: 100,
            }}
            value={details.details}
            onChange={(e) =>
              setDetails({ ...details, details: e.target.value })
            }
          ></textarea>
          <FileUpload
            handleChange={(e) => setDetails({ ...details, file: e })}
          />
          <p className="p">Name Color : </p>
          <input
            type={"color"}
            value={details.nameColor}
            onChange={(e) =>
              setDetails({ ...details, nameColor: e.target.value })
            }
          />
          <br />
          <input
            value={details.nameColor}
            onChange={(e) =>
              setDetails({ ...details, nameColor: e.target.value })
            }
          />
          <br />
          <div className="tow-sided">
            <div className="sx">
              <p className="p">Font size</p>
              <input
                type={"number"}
                value={details.fontSize}
                onChange={(e) =>
                  setDetails({ ...details, fontSize: e.target.value })
                }
              />
            </div>
            <div className="sx">
              <p className="p">Font Family: </p>
              {/* <input
                type={"number"}
                value={details.fontSize}
                onChange={(e) =>
                  setDetails({ ...details, fontSize: e.target.value })
                }
              /> */}
              <select
                value={details.fontFamily}
                onChange={(e) =>
                  setDetails({ ...details, fontFamily: e.target.value })
                }
              >
                <option value={"certificate"}>certificate</option>
                <option value={"kalam-bold-normal"}>Kalam bold normal</option>
                <option value={"niconne"}>Niconne</option>
              </select>
            </div>
          </div>
          <p className="p">Image details:</p>
          <div className="tow-sided">
            <div className="sx">
              <p>height: </p>
              <input type={"number"} value={details.imgHeight} readOnly />
            </div>
            <div className="sx">
              <p>width: </p>
              <input type={"number"} value={details.imgWidth} readOnly />
            </div>
          </div>
          <p className="p">Name position:</p>
          <div className="tow-sided">
            <div className="sx">
              <p>X: </p>
              <input
                type={"number"}
                value={details.x}
                onChange={(e) => setDetails({ ...details, x: e.target.value })}
              />
            </div>
            <div className="sx">
              <p>Y: </p>
              <input
                type={"number"}
                value={details.y}
                onChange={(e) => setDetails({ ...details, y: e.target.value })}
              />
            </div>
          </div>

          {details.file !== null && (
            <div className="preview">
              <p
                style={{
                  transformOrigin: "center",
                  color: details.nameColor,
                  fontSize: details.fontSize + "px",
                  fontWeight: "normal",
                  fontFamily: details.fontFamily,
                  lineHeight: details.fontSize + "px",
                }}
                ref={nameref}
              >
                sample name
              </p>
              <img
                ref={previewref}
                src={URL.createObjectURL(details.file)}
                alt="preview"
              />
              <Button
                style={{
                  width: 150,
                  fontSize: 10,
                  background: "#202020",
                  color: "white",
                  marginTop: 10,
                }}
                onClick={() => generateCertificate(details, user.name, true)}
              >
                Download Preview
              </Button>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateCertificate;
