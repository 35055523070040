const { getMilliseconds } = require("quick-ms");
const getTime = (date, time) => {
  time = time.replace(".", ":");
  date = new Date(date);
  let t = date.toLocaleDateString("en-US");
  date = new Date(t);
  let p = date.getTime() + getMilliseconds(time);
  let x = new Date(p);
  return x.getTime();
};

export default getTime;
