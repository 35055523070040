import React, { createRef, useEffect, useRef, useState } from "react";
import Carousel from "../../components/Carousel/Carousel";
import client from "../../utils/Client";
import { BACKEND_URL } from "../../utils/config";
import color_list from "../../utils/color_list";
const getIdx = (index, mod) => {
  index += mod;
  index %= mod;
  return index;
};
const Achivements = () => {
  const [allt, setAllt] = useState([]);
  const tx1 = useRef();
  const year = useRef();
  let index = 2;
  let timer = null;
  let running = false;
  const startTimer = () => {
    if (timer !== null) {
      clearInterval(timer);
    }
    timer = setInterval(next, 3000);
  };
  let classList = (ab) => {
    return ab.classList[1];
  };
  const next = () => {
    let mod = allt.length;
    if (running) return;
    running = true;
    if (mod === 0) return 0;
    if (timer) clearInterval(timer);
    allt[getIdx(index - 1, mod)].ref.current.classList.add("aaleft");
    allt[getIdx(index, mod)].ref.current.classList.add("aleft");
    allt[getIdx(index + 1, mod)].ref.current.classList.add("aright");
    allt[getIdx(index + 2, mod)].ref.current.classList.add("aaright");
    tx1.current.classList.add("full-left");
    year.current.style.transition = "0.3s all";
    year.current.style.top = "-40px";
    setTimeout(() => {
      let c = classList(allt[mod - 1].ref.current);
      for (let i = mod - 1; i >= 1; i--) {
        allt[i].ref.current.classList.remove(classList(allt[i].ref.current));
        allt[i].ref.current.classList.add(classList(allt[i - 1].ref.current));
      }
      allt[0].ref.current.classList.remove(classList(allt[0].ref.current));
      allt[0].ref.current.classList.add(c);
      allt[getIdx(index - 1, mod)].ref.current.classList.remove("aaleft");
      allt[getIdx(index, mod)].ref.current.classList.remove("aleft");
      allt[getIdx(index + 1, mod)].ref.current.classList.remove("aright");
      allt[getIdx(index + 2, mod)].ref.current.classList.remove("aaright");
      index = getIdx(index + 1, mod);

      year.current.style.transition = "0s all";
      year.current.style.top = "90px";
      year.current.innerHTML = allt[index].year;
      tx1.current.innerHTML = allt[index].details;
      tx1.current.classList.remove("full-left");
      setTimeout(() => {
        year.current.style.transition = "0.2s all";
        year.current.style.top = "0";
      }, 1);
      running = false;
      startTimer();
    }, 400);
  };

  const prev = () => {
    let mod = allt.length;
    if (running) return;
    running = true;
    if (timer) clearInterval(timer);
    if (mod === 0) return 0;
    allt[getIdx(index - 2, mod)].ref.current.classList.add("bbright");
    allt[getIdx(index - 1, mod)].ref.current.classList.add("bright");
    allt[getIdx(index, mod)].ref.current.classList.add("bleft");
    allt[getIdx(index + 1, mod)].ref.current.classList.add("bbleft");
    tx1.current.classList.add("full-right");
    year.current.style.transition = "0.3s all";
    year.current.style.top = "90px";
    setTimeout(() => {
      let c = classList(allt[0].ref.current);
      for (let i = 0; i < mod - 1; i++) {
        allt[i].ref.current.classList.remove(classList(allt[i].ref.current));
        allt[i].ref.current.classList.add(classList(allt[i + 1].ref.current));
      }
      allt[mod - 1].ref.current.classList.remove(
        classList(allt[mod - 1].ref.current)
      );
      allt[mod - 1].ref.current.classList.add(c);
      allt[getIdx(index, mod)].ref.current.classList.remove("bleft");
      allt[getIdx(index - 1, mod)].ref.current.classList.remove("bright");
      allt[getIdx(index + 1, mod)].ref.current.classList.remove("bbleft");
      allt[getIdx(index - 2, mod)].ref.current.classList.remove("bbright");

      index = getIdx(index - 1, mod);
      year.current.style.transition = "0s all";
      year.current.style.top = "-40px";

      tx1.current.innerHTML = allt[index].details;
      tx1.current.classList.remove("full-right");
      year.current.innerHTML = allt[index].year;
      setTimeout(() => {
        year.current.style.transition = "0.2s all";
        year.current.style.top = "0";
      }, 1);
      startTimer();
      running = false;
    }, 400);
  };

  useEffect(() => {
    client.get("/achivements").then(async (res) => {
      if (res.data.status) {
        let temp = [];
        for (let i = 0; i < res.data.achivement.length; i++) {
          let item = res.data.achivement[i];
          let a = {
            img: item.path,
            year: item.year,
            details: item.details,
            ref: createRef(),
          };
          temp.push(a);
        }
        if (temp.length < 5) {
          let i = 0;
          let len = temp.length;
          while (temp.length < 5) {
            temp.push({ ...temp[i], ref: createRef() });
            i++;
            i %= len;
          }
        }
        setAllt([...temp]);
      }
    });
  }, []);
  useEffect(() => {
    if (allt.length) {
      startTimer();
    }
  }, [allt]);
  return (
    <div className="achivements">
      <div className="title">Achivements</div>
      <div className="carousel">
        {allt.map((item, i) => (
          <div
            key={i}
            ref={allt[i].ref}
            className={`a a${i + 1 > 6 ? "6" : i + 1}`}
            style={{
              backgroundColor:
                color_list[Math.floor(Math.random() * (color_list.length - 1))]
                  .color,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: 30,
            }}
          >
            {item.img && <img src={item.img} alt={item.details} />}
          </div>
        ))}
      </div>
      <div className="carousel mobile">
        <Carousel images={allt.map((item) => item.img)} />
      </div>
      <div className="year">
        <p ref={year}>{allt.length > 2 ? allt[2].year : ""}</p>
      </div>
      <div className="navigation">
        <div onClick={next} className="left">
          <svg
            width="87"
            height="76"
            viewBox="0 0 87 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M60.1792 49.992L50.1875 40.0004L60.1792 30.0088L61.8585 31.6882L54.7337 38.813L85.7558 38.813L85.7558 41.188L54.7339 41.188L61.8585 48.3126L60.1792 49.992Z"
              fill="#231F20"
            />
            <circle
              r="33"
              transform="matrix(-1 0 0 1 38 38)"
              fill="#231F20"
              fillOpacity="0.1"
            />
            <mask id="path-3-inside-1_115_180" fill="white">
              <path d="M73.6371 51.1907C70.4794 59.722 64.3658 66.8375 56.4074 71.2441C48.4489 75.6507 39.1737 77.0561 30.267 75.2048C21.3604 73.3536 13.4134 68.3686 7.87003 61.1557C2.32668 53.9427 -0.445137 44.9805 0.0582123 35.8974C0.561569 26.8143 4.30669 18.2132 10.6131 11.6569C16.9195 5.10066 25.3686 1.02428 34.4253 0.168513C43.4819 -0.687255 52.5451 1.73438 59.9679 6.99338C67.3907 12.2524 72.6806 19.9998 74.8764 28.8278L73.7874 29.0987C71.6565 20.5313 66.5228 13.0127 59.3192 7.90904C52.1156 2.80533 43.3201 0.455218 34.5309 1.28571C25.7416 2.11621 17.542 6.07221 11.4218 12.4349C5.30169 18.7975 1.66716 27.1447 1.17867 35.9595C0.690186 44.7743 3.38015 53.4719 8.7598 60.4719C14.1394 67.4718 21.8518 72.3096 30.4954 74.1062C39.139 75.9027 48.1403 74.5388 55.8638 70.2624C63.5872 65.9859 69.5202 59.0805 72.5847 50.8011L73.6371 51.1907Z" />
            </mask>
            <path
              d="M73.6371 51.1907C70.4794 59.722 64.3658 66.8375 56.4074 71.2441C48.4489 75.6507 39.1737 77.0561 30.267 75.2048C21.3604 73.3536 13.4134 68.3686 7.87003 61.1557C2.32668 53.9427 -0.445137 44.9805 0.0582123 35.8974C0.561569 26.8143 4.30669 18.2132 10.6131 11.6569C16.9195 5.10066 25.3686 1.02428 34.4253 0.168513C43.4819 -0.687255 52.5451 1.73438 59.9679 6.99338C67.3907 12.2524 72.6806 19.9998 74.8764 28.8278L73.7874 29.0987C71.6565 20.5313 66.5228 13.0127 59.3192 7.90904C52.1156 2.80533 43.3201 0.455218 34.5309 1.28571C25.7416 2.11621 17.542 6.07221 11.4218 12.4349C5.30169 18.7975 1.66716 27.1447 1.17867 35.9595C0.690186 44.7743 3.38015 53.4719 8.7598 60.4719C14.1394 67.4718 21.8518 72.3096 30.4954 74.1062C39.139 75.9027 48.1403 74.5388 55.8638 70.2624C63.5872 65.9859 69.5202 59.0805 72.5847 50.8011L73.6371 51.1907Z"
              fill="#231F20"
              stroke="#231F20"
              strokeWidth="6"
              mask="url(#path-3-inside-1_115_180)"
            />
          </svg>
        </div>
        <div className="caption">
          <div ref={tx1} className="text text-1">
            {allt.length > 2 ? allt[2].details : ""}
          </div>
        </div>
        <div onClick={prev} className="right">
          <svg
            width="87"
            height="76"
            viewBox="0 0 87 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.8208 49.992L36.8125 40.0004L26.8208 30.0088L25.1415 31.6882L32.2663 38.813L1.24421 38.813L1.2442 41.188L32.2661 41.188L25.1415 48.3126L26.8208 49.992Z"
              fill="#231F20"
            />
            <circle cx="49" cy="38" r="33" fill="#231F20" fillOpacity="0.1" />
            <mask id="path-3-inside-1_115_165" fill="white">
              <path d="M13.3629 51.1907C16.5206 59.722 22.6342 66.8375 30.5926 71.2441C38.5511 75.6507 47.8263 77.0561 56.733 75.2048C65.6396 73.3536 73.5866 68.3686 79.13 61.1557C84.6733 53.9427 87.4451 44.9805 86.9418 35.8974C86.4384 26.8143 82.6933 18.2132 76.3869 11.6569C70.0805 5.10066 61.6314 1.02428 52.5747 0.168513C43.5181 -0.687255 34.4549 1.73438 27.0321 6.99338C19.6093 12.2524 14.3194 19.9998 12.1236 28.8278L13.2126 29.0987C15.3435 20.5313 20.4772 13.0127 27.6808 7.90904C34.8844 2.80533 43.6799 0.455218 52.4691 1.28571C61.2584 2.11621 69.458 6.07221 75.5782 12.4349C81.6983 18.7975 85.3328 27.1447 85.8213 35.9595C86.3098 44.7743 83.6199 53.4719 78.2402 60.4719C72.8606 67.4718 65.1482 72.3096 56.5046 74.1062C47.861 75.9027 38.8597 74.5388 31.1362 70.2624C23.4128 65.9859 17.4798 59.0805 14.4153 50.8011L13.3629 51.1907Z" />
            </mask>
            <path
              d="M13.3629 51.1907C16.5206 59.722 22.6342 66.8375 30.5926 71.2441C38.5511 75.6507 47.8263 77.0561 56.733 75.2048C65.6396 73.3536 73.5866 68.3686 79.13 61.1557C84.6733 53.9427 87.4451 44.9805 86.9418 35.8974C86.4384 26.8143 82.6933 18.2132 76.3869 11.6569C70.0805 5.10066 61.6314 1.02428 52.5747 0.168513C43.5181 -0.687255 34.4549 1.73438 27.0321 6.99338C19.6093 12.2524 14.3194 19.9998 12.1236 28.8278L13.2126 29.0987C15.3435 20.5313 20.4772 13.0127 27.6808 7.90904C34.8844 2.80533 43.6799 0.455218 52.4691 1.28571C61.2584 2.11621 69.458 6.07221 75.5782 12.4349C81.6983 18.7975 85.3328 27.1447 85.8213 35.9595C86.3098 44.7743 83.6199 53.4719 78.2402 60.4719C72.8606 67.4718 65.1482 72.3096 56.5046 74.1062C47.861 75.9027 38.8597 74.5388 31.1362 70.2624C23.4128 65.9859 17.4798 59.0805 14.4153 50.8011L13.3629 51.1907Z"
              fill="#231F20"
              stroke="#231F20"
              strokeWidth="6"
              mask="url(#path-3-inside-1_115_165)"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Achivements;
