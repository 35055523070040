
const color_list = [
    {
      color: "#34568B",
      year: 2021,
      text: "sample text 1",
    },
    {
      color: "#FF6F61",
      year: 2022,
      text: "sample text 2",
    },
    {
      color: "#6B5B95",
      year: 2023,
      text: "sample text 3",
    },
    {
      color: "#88B04B",
      year: 2024,
      text: "sample text 4",
    },
    {
      color: "#F7CAC9",
      year: 2026,
      text: "sample text 5",
    },
    {
      color: "#92A8D1",
      year: 2010,
      text: "sample text 6",
    },
    {
      color: "#DD4124",
      year: 2004,
      text: "sample text 7",
    },
    {
      color: "#009B77",
      year: 2022,
      text: "sample text 8",
    },
    {
      color: "#D65076",
      year: 2022,
      text: "sample text 9",
    },
    {
      color: "#45B8AC",
      year: 2022,
      text: "sample text 10",
    },
  ];
  
  export default color_list