import React from "react";
import { useNavigate } from "react-router-dom";
import { aboutusvideo } from "../../utils/basicdata";

const About = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="hiden-item-xxy"></div>
      <div className="about-us">
        <div className="video">
          <video loop={true} muted={true} autoPlay={true} controls>
            <source src={aboutusvideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="hiden-item-iix"></div>
        </div>

        <div className="about-us-text">
          <div className="title title-1">About pcIST</div>
          <p>
            Programming Club of IST (pcIST) is the one and only Engineering club
            in CSE department of IST, established in 2007. pcIST is the club for
            students who are interested to build themselves with great skill and
            quality. It has devoted itself towards one large goal, ‘To Lead the
            Next Generation with Glory’, with all the active members of the
            club.
          </p>
          <button onClick={() => navigate("/about-us")}>See More</button>
        </div>
      </div>
    </>
  );
};

export default About;
