import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import client from "../../utils/Client";

const ChangePassword = () => {
  const [passwords, setPasswords] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });
  const dispatch = useDispatch();
  const update = () => {
    if (passwords.confirmpassword !== passwords.newpassword) {
      dispatch(
        open_snackbar({
          type: "error",
          message: "confirm password doesn't match",
        })
      );
      return;
    }
    dispatch(open_loader());
    client.post("/user/change-password", passwords).then((res) => {
      dispatch(close_loader());
      dispatch(
        open_snackbar({
          type: res.data.status ? "success" : "error",
          message: res.data.message,
        })
      );
    });
  };
  return (
    <div className="change-password">
      <div
        style={{
          marginBottom: 50,
        }}
        className="title"
      >
        Change Password
      </div>
      <p className="semi-title">Current password</p>
      <input
        style={{
          width: "70%",
        }}
        className="input-1"
        value={passwords.oldpassword}
        placeholder="current password"
        type={"password"}
        onChange={(e) =>
          setPasswords({ ...passwords, oldpassword: e.target.value })
        }
      />

      <p className="semi-title">New password</p>
      <input
        style={{
          width: "70%",
        }}
        className="input-1"
        value={passwords.newpassword}
        placeholder="New password"
        type={"password"}
        onChange={(e) =>
          setPasswords({ ...passwords, newpassword: e.target.value })
        }
      />
      <p className="semi-title">Confirm password</p>
      <input
        style={{
          width: "70%",
        }}
        className="input-1"
        value={passwords.confirmpassword}
        placeholder="Confirm Password"
        type={"password"}
        onChange={(e) =>
          setPasswords({ ...passwords, confirmpassword: e.target.value })
        }
      />
      <button onClick={update} className="post-button pass-change">
        Change Password
      </button>
    </div>
  );
};

export default ChangePassword;
