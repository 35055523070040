import imageCompression from "browser-image-compression";
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const CompressImage = async (imagefile) => {
  console.error(imagefile)
  let image = await fetch(imagefile);
  image = await image.blob();
  const compressedFile = await imageCompression(image, options);
  return URL.createObjectURL(compressedFile);
};

export const CompressImageFile = async (image) => {
  return await imageCompression(image, options);
  
};

export default CompressImage;
