import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useEffect } from "react";
import client from "../../utils/Client";
import { Link, useParams } from "react-router-dom";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import { Button } from "@mui/material";
const AllTeams = () => {
  const [rows, setRows] = React.useState([]);
  const user = useSelector((state) => state.user);
  const slug = useParams().slug;

  const [ranks, setRanks] = useState({});
  const dispatch = useDispatch();
  const changeApprove = (teamId, id) => {
    dispatch(open_loader());
    client.post("/team/approve-team/" + teamId).then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        let temp = rows;
        temp[id].approved =
          temp[id].approved === "approved" ? "not-approved" : "approved";
        setRows([...temp]);
      }
    });
  };
  const UpdateRank = () => {
    dispatch(open_loader());
    let standings = Object.keys(ranks).map((item) => ({
      teamId: item,
      rank: ranks[item],
    }));
    client.post("/contest/standings/" + slug, { standings }).then((res) => {
      dispatch(close_loader());
      dispatch(
        open_snackbar({
          type: res.data.status ? "success" : "error",
          message: res.data.message,
        })
      );
    });
  };
  const handleInputChange = (e, i, teamId) => {
    console.log(teamId);
    setRanks({
      ...ranks,
      [teamId]: e.target.value,
    });
  };
  const columns = [
    { field: "teamName", headerName: "Team Name", width: 230, sortable: true },
    {
      field: "FirstPersonRoll",
      headerName: "First Member Roll",
      width: 230,
      sortable: true,
      renderCell: (value) => {
        return <Link to={"/users/" + value.value}>{value.value}</Link>;
      },
    },
    {
      field: "SecondPersonRoll",
      headerName: "Second Member Roll",
      width: 230,
      sortable: true,
      renderCell: (value) => {
        return <Link to={"/users/" + value.value}>{value.value}</Link>;
      },
    },
    {
      field: "ThirdPersonRoll",
      headerName: "Third Member Roll",
      width: 230,
      sortable: true,
      renderCell: (value) => {
        return <Link to={"/users/" + value.value}>{value.value}</Link>;
      },
    },
    {
      field: "approved",
      headerName: "Approve",
      width: 100,
      sortable: true,
      renderCell: (value) => {
        return (
          <button
            onClick={() => changeApprove(value.row.teamId, value.id)}
            className={`approved-button ${value.value}`}
          ></button>
        );
      },
    },
    {
      field: "rank",
      sortable: false,

      headerName: (
        <div>
          Ranks{" "}
          <Button
            style={{
              height: 22,
              width: 50,
              fontSize: 10,
              borderRadius: 4,
              marginLeft: 10,
            }}
            variant="contained"
            color="success"
            onClick={UpdateRank}
          >
            Save
          </Button>
        </div>
      ),
      width: 200,
      renderCell: (value) => {
        return (
          <input
            style={{
              width: 60,
            }}
            value={ranks[value.row.teamId]}
            onChange={(e) => handleInputChange(e, value.id, value.row.teamId)}
          />
        );
      },
    },
  ];
  useEffect(() => {
    client.get("/team/all-team/" + slug).then((res) => {
      if (res.data.status) {
        console.log(res.data.teams);
        let teams = res.data.teams.map((item, i) => ({
          id: i,
          teamName: item.name,
          FirstPersonRoll: item.members.first,
          SecondPersonRoll: item.members.second,
          ThirdPersonRoll: item.members.third,
          approved: item.paymentStatus ? "approved" : "not-approved",
          teamId: item._id,
          rank: item.rank,
        }));
        let obj = {};
        res.data.teams.forEach((item) => {
          obj[item._id] = item.rank;
        });
        setRanks({ ...obj });
        setRows([...teams]);
      }
    });
  }, []);
  return (
    <>
      <Header />
      <div className="All-teams" style={{ height: "90vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[1000]}
          disableColumnSelector={true}
        />
      </div>
      <Footer />
    </>
  );
};

export default AllTeams;
