import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import client from "../../utils/Client";
import "./style.scss";
import CountDown from "../../components/CountDown/CountDown";
import getTime from "../../utils/getTime";
import RichTextView from "../../components/RichTextView/RichTextView";
const months = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];
const EventDetails = () => {
  const slug = useParams().slug;
  const [contest, setContestDetails] = useState({});
  const dispatch = useDispatch();
  const [contestNotFound, setContestNotFound] = useState(false);
  useEffect(() => {
    dispatch(open_loader());
    client.get("/events/" + slug).then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        setContestDetails({ ...res.data.events });
      } else {
        dispatch(close_loader());
        setContestNotFound(true);
      }
    });
  }, []);
  if (contestNotFound) {
    return (
      <>
        <Header />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "60vh",
          }}
          className="contest-not-found"
        >
          <h1>Event Not Found</h1>
        </div>
        <Footer />
      </>
    );
  }
  const _getDate = (date) => {
    date = new Date(date);
    return {
      mon: months[date.getMonth()],
      date: date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
      year: date.getFullYear(),
    };
  };

  return (
    <>
      <Header />
      <div className="contest-details-xy">
        <div className="Contest-details">
          {contest.banner && (
            <div className="contest-banner">
              <img src={contest.banner} alt="banner" />
            </div>
          )}
        </div>

        <div className="details">
          <div className="details-side">
            <h1>{contest.name}</h1>
            <div className="date-sec">
              <p>Date : </p>
              <p>{_getDate(contest.date).date}</p>{" "}
              <p>{_getDate(contest.date).mon}</p>
              <p>{_getDate(contest.date).year}</p>
            </div>
            <p>Time : {contest.time}</p>
          </div>
          {contest !== null &&
            contest.date != undefined &&
            contest.time != undefined && (
              <div className="contdown-zone">
                <CountDown
                  endTime={getTime(contest.date, contest.time)}
                  className={"count-down"}
                />
              </div>
            )}
        </div>
        <div className="details-section">
          {" "}
          <RichTextView text={contest.details} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EventDetails;
