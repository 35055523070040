import React, { useEffect, useState } from "react";
import client from "../../utils/Client";

const UpdateStandings = ({ contestSlug, setContestSlug }) => {
  const [ranks, setRanks] = useState([]);
  const [notFound, setNotFound] = useState(true);
  const [team, setTeam] = useState({ name: "", rank: 0 });
  const [contest, setContest] = useState({});
  let SearchCancel = null;
  const update = (e) => {
    e.preventDefault();
    if (notFound === false) return;
    client.get("/team/" + contest._id + "/" + team.name).then((res) => {
      if (res.data.status) {
        setRanks([
          ...ranks,
          {
            name: team.name,
            teamid: res.data.team._id,
          },
        ]);
      } else alert(res.data.message);
    });
  };
  const _updateStanding = () => {
    client
      .post("/contest/standings/" + contestSlug, {
        ranks: ranks.map((item) => item.teamid),
      })
      .then((res) => {
        console.log(res.data);
      });
  };
  useEffect(() => {
    if (!contestSlug || contestSlug.length === 0) {
      setNotFound(false);
      return;
    }
    if (SearchCancel) SearchCancel.abort();
    SearchCancel = new AbortController();
    client
      .get("/contest/" + contestSlug, {
        signal: SearchCancel.signal,
      })
      .then((res) => {
        if (!res.data.status || !res.data.contest) {
          setNotFound(false);
          setContest({});
        } else {
          setNotFound(true);
          setContest({ ...res.data.contest });
        }
      })
      .catch((err) => {
        console.log("contest slug changed");
      });
  }, [contestSlug]);
  const deleteTeam = (id) => {
    const rankss = ranks;
    ranks.splice(id, 1);
    setRanks([...rankss]);
  };
  return (
    <div>
      <div className="title">Update Contest Standings</div>
      <form method="post" action="/" className="form">
        <p>Contest slug</p>
        <input
          placeholder="Contest slug"
          style={{ width: "75%", borderColor: !notFound ? "red" : null }}
          className="input-1"
          value={contestSlug ? contestSlug : ""}
          onChange={(e) => setContestSlug(e.target.value)}
        />
        <p>team Name</p>
        <input
          placeholder="Team Name"
          style={{ width: "75%" }}
          className="input-1"
          value={team.name}
          onChange={(e) => setTeam({ ...team, name: e.target.value })}
        />
        {/* <p>Team Position</p>
        <input
          value={team.rank}
          onChange={(e) => setTeam({ ...team, rank: e.target.value })}
          type="number"
          className="input-2"
        /> */}

        <button
          type="submit"
          onClick={update}
          className="post-button update-button"
        >
          Add
        </button>
      </form>

      <div className="all-teams">
        {ranks.map((item, i) => (
          <div key={i} className="single-rank">
            <div className="left-side">
              <div className="team-name">{item.name}</div>
              <div className="team-position">Rank : {i + 1}</div>
            </div>
            <div className="right-side">
              <button onClick={() => deleteTeam(i)}>
                <svg
                  width="24"
                  height="27"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.7875 27C3.1875 27 2.6625 26.775 2.2125 26.325C1.7625 25.875 1.5375 25.35 1.5375 24.75V3.375H0V1.125H7.05V0H16.95V1.125H24V3.375H22.4625V24.75C22.4625 25.35 22.2375 25.875 21.7875 26.325C21.3375 26.775 20.8125 27 20.2125 27H3.7875ZM7.7625 21.525H10.0125V6.5625H7.7625V21.525ZM13.9875 21.525H16.2375V6.5625H13.9875V21.525Z"
                    fill="#D83333"
                  />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
      {ranks.length > 0 && (
        <button
          onClick={_updateStanding}
          className="post-button update-standings"
        >
          Update Standings
        </button>
      )}
    </div>
  );
};

export default UpdateStandings;
