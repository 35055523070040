import React, { useState } from "react";
import Header from "../../components/Header/Header";
import "./style.scss";
import color_list from "../../utils/color_list";
import Footer from "../../components/Footer/Footer";
import { BsFillTrashFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import client from "../../utils/Client";
import { useEffect } from "react";
import { GrAddCircle } from "react-icons/gr";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FileUploader } from "react-drag-drop-files";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import { open_loader, close_loader } from "../../redux/Loader/LoaderActions";
import { aboutusvideo } from "../../utils/basicdata";
const fileTypes = ["JPG", "PNG", "jpg", "jpeg", "png"];
const Aboutus = () => {
  const user = useSelector((state) => state.user);
  const [open, setOpen] = React.useState(false);
  const [images, setImages] = useState([]);
  const [uploadImageDetails, setUploadImageDetails] = useState({
    file: null,
    details: "",
  });
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const deleteGalleryImage = (id) => {
    dispatch(open_loader());
    client.delete("/gallery/" + id).then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        fetchGalleryImage();
        dispatch(
          open_snackbar({
            type: "success",
            message: res.data.message,
          })
        );
      } else {
        dispatch(
          open_snackbar({
            type: "error",
            message: res.data.message,
          })
        );
      }
    });
  };
  const uploadGallerImage = () => {
    let formData = new FormData();
    if (uploadImageDetails.file === null) {
      dispatch(
        open_snackbar({
          type: "error",
          message: "file can't be empty",
        })
      );
    }
    formData.append("details", uploadImageDetails.details);
    formData.append("file", uploadImageDetails.file);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    dispatch(open_loader());
    client.post("/gallery", formData, config).then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        handleClose();
        setUploadImageDetails({
          file: null,
          details: "",
        });
        fetchGalleryImage();
        dispatch(
          open_snackbar({
            type: "success",
            message: "Image upload successfully",
          })
        );
      } else {
        dispatch(
          open_snackbar({
            type: "error",
            message: res.data.message,
          })
        );
      }
    });
  };
  const fetchGalleryImage = () => {
    dispatch(open_loader());
    client.get("/gallery").then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        setImages([...res.data.images]);
      }
    });
  };
  useEffect(() => {
    fetchGalleryImage();
  }, []);
  const handleChange = (e) => {
    setUploadImageDetails({ ...uploadImageDetails, file: e });
  };
  return (
    <>
      <Header />
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="add-image-modal-root"
          scroll={"paper"}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Add Image
          </BootstrapDialogTitle>
          <DialogContent className="modal-body" dividers={true}>
            <p className="short-details">Short Details : </p>
            <input
              type="text"
              value={uploadImageDetails.details}
              placeholder="Short Details"
              onChange={(e) =>
                setUploadImageDetails({
                  ...uploadImageDetails,
                  details: e.target.value,
                })
              }
            />
            {uploadImageDetails.file && (
              <div className="preview">
                <img src={URL.createObjectURL(uploadImageDetails.file)} />
              </div>
            )}
            <FileUploader
              children={
                <div className="upload-box">
                  <div className="upload-icon">
                    <svg
                      width="138"
                      height="100"
                      viewBox="0 0 138 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M33.0625 100C24.1042 100 16.3958 96.7708 9.9375 90.3125C3.47917 83.8542 0.25 76.1458 0.25 67.1875C0.25 59.0625 2.82812 51.901 7.98437 45.7031C13.1406 39.5052 19.7813 35.7812 27.9063 34.5312C29.9896 24.4271 34.8854 16.1719 42.5938 9.76562C50.3021 3.35937 59.2083 0.15625 69.3125 0.15625C81.0833 0.15625 90.9531 4.40104 98.9219 12.8906C106.891 21.3802 110.875 31.5625 110.875 43.4375V47.1875C118.375 46.9792 124.729 49.401 129.938 54.4531C135.146 59.5052 137.75 65.8854 137.75 73.5938C137.75 80.7812 135.146 86.9792 129.938 92.1875C124.729 97.3958 118.531 100 111.344 100H73.6875C71.1875 100 69 99.0625 67.125 97.1875C65.25 95.3125 64.3125 93.125 64.3125 90.625V50.3125L51.3438 63.2813L44.625 56.5625L69 32.1875L93.375 56.5625L86.6562 63.2813L73.6875 50.3125V90.625H111.344C116.031 90.625 120.042 88.9583 123.375 85.625C126.708 82.2917 128.375 78.2812 128.375 73.5938C128.375 68.9062 126.708 64.8958 123.375 61.5625C120.042 58.2292 116.031 56.5625 111.344 56.5625H101.5V43.4375C101.5 34.1667 98.349 26.1979 92.0469 19.5312C85.7448 12.8646 77.9583 9.53125 68.6875 9.53125C59.4167 9.53125 51.6042 12.8646 45.25 19.5312C38.8958 26.1979 35.7188 34.1667 35.7188 43.4375H32.75C26.2917 43.4375 20.8229 45.7031 16.3438 50.2344C11.8646 54.7656 9.625 60.3646 9.625 67.0313C9.625 73.4896 11.9167 79.0365 16.5 83.6719C21.0833 88.3073 26.6042 90.625 33.0625 90.625H54.9375V100H33.0625Z"
                        fill="#404040"
                      />
                    </svg>
                  </div>
                  <p>Drag and Drop or browse to choose a file</p>
                </div>
              }
              handleChange={handleChange}
              name="file"
              types={fileTypes}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={uploadGallerImage}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className="About-us">
        <div
          style={{
            backgroundImage: "url('/Assets/about-us-top.svg')",
          }}
          className="about-us-header"
        >
          <div className="title">
            <p className="yx">About</p>
            <p className="xy">PCIST</p>
          </div>
        </div>

        <div className="body">
          <div className="about-us">
            <div className="video">
              <video loop={true} muted={true} autoPlay={true} controls>
                <source src={aboutusvideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div className="about-us-text">
              <p>
                Programming Club of IST (pcIST) is the one and only Engineering
                club in CSE department of <a href="https://ist.edu.bd">IST</a>,
                established in 2007. pcIST is the club for students who are
                interested to build themselves with great skill and quality. It
                has devoted itself towards one large goal, ‘To Lead the Next
                Generation with Glory’, with all the active members of the club.
                The main objective of pcIST is to promote the CSE students of
                IST to offer an extensive study of the IT and Software
                industries of Bangladesh through contest programming,
                programming language-based courses, seminars, workshops,
                technical sessions, poster presentation, project exhibition and
                publications. We encourage students to learn programming,
                development and want to get creative ideas from them to keep up
                the pace of our club.
              </p>
              <br />
              <p>
                pcIST has already begun its journey with very promising results
                by participating in ACM ICPC, NCPC, NGPC, NASA Space Apps
                Challenge, Hackathon and several IUPC. We have over 500
                memberships from the IST students. pcIST also organize Internal
                Programming Contest (IPC) every year. Besides, pcIST also
                organizes event like Gaming Contest, Software Exhibition, and
                Workshop on programming topics. It organized the TOP-UP IT
                training program in IST run by the IT Division of Bangladesh
                Government.
              </p>
              <p>There some major sections of our club are as follows —</p>
              <ul className="list">
                <li>Contest Programmers Section</li>
                <li>ICU for Programmers Section</li>
                <li>Software Support Section</li>
                <li>Software Development Section</li>
                <li>Edutainment Section</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="title-gallery">Gallery</div>
        <div className="gallery">
          {user?.role === "admin" && (
            <div
              onClick={handleClickOpen}
              style={{
                background: "white",
              }}
              className="gallery-item add-button"
            >
              <GrAddCircle />
            </div>
          )}
          {images.map((item, i) => (
            <div key={i} className="gallery-item">
              {user?.role === "admin" ? (
                <div
                  onClick={() => deleteGalleryImage(item._id)}
                  className="delete-button"
                >
                  <BsFillTrashFill />
                </div>
              ) : null}
              <div className="background-black"></div>
              {item.details !== "undefined" && (
                <div className="gallery-short-text">{item.details}</div>
              )}
              <div className="background-black-xy">
                <img src={item.path} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Aboutus;

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
