import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "../../components/Carousel/Carousel";
import LandingPageImageUpdate from "./LandingPageImageUpdate";
import { open_loader, close_loader } from "../../redux/Loader/LoaderActions";
import client from "../../utils/Client";
import { BACKEND_URL } from "../../utils/config";
const LandingPage = () => {
  const user = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const fetchImage = async () => {
    dispatch(open_loader());
    client.get("/landingpageimages").then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        setImages([...res.data.images]);
      }
    });
  };
  useEffect(() => {
    fetchImage();
  }, []);
  return (
    <div className="Landing-page">
      <LandingPageImageUpdate
        open={open}
        setOpen={setOpen}
        fetchImage={fetchImage}
        images={images}
      />
      <div className="right-image">
        <img src="Assets/black-lines.hometop.svg" alt="home-page" />
      </div>
      <div className="roh-image" src="Assets/heroimage.svg" alt="">
        <Carousel
          className={"landing-page-carousel"}
          images={images.map((item) =>  item.path)}
        />
        {user !== null && user.role === "admin" && (
          <button onClick={() => setOpen(true)} className="edit-button">
            Edit
          </button>
        )}
      </div>
      <div className="roh-text">
        <h1>PROGRAMMING CLUB OF IST</h1>

        <a
          target={"_blank"}
          style={{
            textDecoration: "none",
            fontWeight: "bolder",
          }}
          href="https://ist.edu.bd"
        >
          <p
            style={{
              marginTop: 0,
              fontSize: 12,
            }}
          >
            Institute of Science and Techonology
          </p>
        </a>
        <p>
          Programming Club of IST (pcIST) is the one and only Engineering club
          in CSE department of IST, established in 2007...
        </p>

        <a
          href="https://ist.edu.bd/programming-club-of-ist-pcist/"
          target={"_blank"}
        >
          <button>Learn More</button>
        </a>
      </div>
    </div>
  );
};

export default LandingPage;
