import jsPDF from "jspdf";
import { PDFToImage } from "react-pdf-to-image-light";
import { certificateFont } from "./CertificateFonts";
import { niconne } from "./niconne";
import { kalamboldnormal } from "./Kalam-Bold-normal";
const generateCertificate = async (
  details,
  myname,
  download,
  previewimgref,
  ref,
  pngdownload,
  pdfdownload,
  cerid
) => {
  let pdf = new jsPDF("landscape", "px", "a4", "false");
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (details.imgHeight * pdfWidth) / details.imgWidth;
  let img = null;
  if (details.img) {
    details.img = details.img.replace("http://", "https://");
    let res = await fetch(details.img);
    res = await res.blob();
    img = res;
  }
  pdf.addImage(
    img ? URL.createObjectURL(img) : URL.createObjectURL(details.file),
    "PNG",
    0,
    0,
    pdfWidth,
    pdfHeight
  );
  let x = (details.y / details.imgWidth) * pdf.internal.pageSize.getWidth();
  let y = (details.x / details.imgHeight) * pdf.internal.pageSize.getHeight();
  let ac = pdf.getTextDimensions(myname, {
    fontSize: details.fontSize,
  });
  pdf.addFileToVFS("hello.ttf", kalamboldnormal);
  pdf.addFont("hello.ttf", "kalam-bold-normal", "normal");
  pdf.addFileToVFS("niconne.ttf", niconne);
  pdf.addFont("niconne.ttf", "niconne", "normal");
  pdf.addFileToVFS("certificate.ttf", certificateFont);
  pdf.addFont("certificate.ttf", "certificate", "normal");
  pdf
    .setFont(details.fontFamily, "normal")
    .setTextColor(details.nameColor)
    .setFontSize(details.fontSize)
    .text(myname, y, x, {
      align: "center",
    });

  if (previewimgref && previewimgref.current) {
    PDFToImage({
      blob: pdf.output("bloburi"), // source pdf base64 string. need to be starting with the prefix "data:application/pdf;base64,"
      scale: 0.4,
    })
      .then((result) => {
        previewimgref.current.src = result[0];
      })
      .catch(console.error);

    return;
  }
  if (download) {
    pdf.save("preview.pdf");
  } else if (pdfdownload.current && pngdownload.current) {
    pdf.setFont("Courier", "normal");
    pdf
      .setTextColor("black")
      .setFontSize(10)
      .text("ID: " + cerid, 20, 12);
    pdfdownload.current.download = myname + ".pdf";
    pdfdownload.current.href = pdf.output("bloburl");
    PDFToImage({
      blob: pdf.output("bloburi"), // source pdf base64 string. need to be starting with the prefix "data:application/pdf;base64,"
      scale: 5,
    })
      .then((result) => {
        pngdownload.current.download = myname + ".jpeg";
        pngdownload.current.href = result[0];
        ref.current.src = result[0];
      })
      .catch(console.error);
  }
};

export default generateCertificate;
