import { CLOSE_LOADER, OPEN_LOADER } from "../types";

const init = false;

const LoaderReducer = (state = init, action = {}) => {
  switch (action.type) {
    case OPEN_LOADER:
      return (state = true);
    case CLOSE_LOADER:
      return (state = false);
    default:
      return state;
  }
};

export default LoaderReducer;
