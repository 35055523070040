import React from "react";
import options from "./options";
import { Link, Navigate } from "react-router-dom";
const Menues = ({activeTab}) => {
  return (
    <div
      style={{
        backgroundImage: "url(/Assets/black-lines.jpg",
      }}
      className="menues"
    >
      {options.map((item, id) => (
        <Link key={id} to={window.location.pathname + "?Tab=" + item.tab}>
          <div
            className={`single-item${item.tab === activeTab ? " active" : ""}`}
          >
            {item.icon}
            <div className="item-text">{item.name} </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Menues;
