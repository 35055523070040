import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./style.css";
import { Autoplay, Pagination, Navigation } from "swiper";

const color_list = [
  {
    color: "#34568B",
    year: 2021,
    text: "sample text 1",
  },
  {
    color: "#FF6F61",
    year: 2022,
    text: "sample text 2",
  },
  {
    color: "#6B5B95",
    year: 2023,
    text: "sample text 3",
  },
  {
    color: "#88B04B",
    year: 2024,
    text: "sample text 4",
  },
  {
    color: "#F7CAC9",
    year: 2026,
    text: "sample text 5",
  },
  {
    color: "#92A8D1",
    year: 2010,
    text: "sample text 6",
  },
  {
    color: "#DD4124",
    year: 2004,
    text: "sample text 7",
  },
  {
    color: "#009B77",
    year: 2022,
    text: "sample text 8",
  },
  {
    color: "#D65076",
    year: 2022,
    text: "sample text 9",
  },
  {
    color: "#45B8AC",
    year: 2022,
    text: "sample text 10",
  },
];

const Carousel = ({ images, className }) => {
  return (
    <div className={`Carousel ${className?className : ""}`}>
      <Swiper
        slidesPerView={1}
        loop={true}
        pagination={false}
        navigation={false}
        className="mySwiper"
        modules={[Autoplay, Pagination, Navigation]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {!images &&
          color_list.map((item, i) => (
            <SwiperSlide key={i}>
              <div
                style={{
                  backgroundColor: item.color,
                }}
                className="car-item"
              >
                slide {i + 1}
              </div>
            </SwiperSlide>
          ))}
        {images &&
          images.map((item, i) => (
            <SwiperSlide key={i}>
              <div
                style={{
                  backgroundColor: item.color,
                }}
                className="car-item"
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  src={item}
                  alt="carousel"
                />
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
