import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import client from "../../utils/Client";
import splitintotwo from "../../utils/splitintotwo";
import { CgFacebook } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import { FiLinkedin } from "react-icons/fi";
import "./style.scss";
const Contributors = () => {
  const con = [
    {
      roll: 18032,
      tech: "Backend",
    },
    {
      roll: 19057,
      tech: "Frontend and Backend",
    },
    {
      roll: 20021,
      tech: "UI/UX",
    },
  ];

  const [contributors, setContributors] = useState([...con]);
  let getDetails = async () => {
    let temp = con;
    for (let i = 0; i < con.length; i++) {
      let res = await client.get("/user/details/" + con[i].roll);
      if (res.data.status) {
        temp[i].profilePic = res.data.user.profilePic;
        temp[i].li = res.data.user.linkedin;
        temp[i].fb = res.data.user.facebook;
        temp[i].gh = res.data.user.github;
        temp[i].name = splitintotwo(res.data.user.name);
      }
    }
    setContributors([...temp]);
  };
  useEffect(() => {
    getDetails();
  }, []);
  console.log(contributors);
  return (
    <>
      <Header />
      <div className="contributors">
        <h1>Contributors</h1>
        {/* <div className="c-text">
          “The achievements of an organization are the results of the combined
          effort of each individual.” – <span>Vince Lombardi</span>
        </div> */}
        <div className="butors">
          {contributors.map((item, i) => (
            <div className="contributor">
              <div className="profile-pic">
                <img src={item.profilePic} alt="" />
              </div>
              <a
                target={"_blank"}
                href={`/users/${item.roll}`}
                className="name"
              >
                {item.name}
              </a>
              <div className="tech">{item.tech}</div>
              <div className="social">
                <a target={"_blank"} href={item.fb}>
                  <CgFacebook />
                </a>
                <a target={"_blank"} href={item.gh}>
                  <BsGithub />
                </a>
                <a target={"_blank"} href={item.li}>
                  <FiLinkedin />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contributors;
