import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function RitchTextEditorPro({ value, setValue, height }) {
  return (
    <ReactQuill
      modules={{
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, false] }],
          ["bold", "italic", "underline"],
          ["code-block"],
          ["link"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ color: [] }, { background: [] }],
        ],
      }}
      className="ritch-text-pro"
      theme="snow"
      value={value}
      style={{
        height: height ? height : "unset",
      }}
      onChange={setValue}
    />
  );
}

export default RitchTextEditorPro;
