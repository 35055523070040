import React from "react";
import { useSelector } from "react-redux";
import { Navigate} from "react-router-dom";
export function AuthenticatedRouteForAdmin({
  component: C,
  appProps,
  ...rest
}) {
  const user = useSelector((state) => state.user);
  if (!user || !(user.role === "admin"))
    return <Navigate to="/" replace />;
  return C;
}

export function AuthenticatedRouteForUser({ component: C, appProps, ...rest }) {
  const user = useSelector((state) => state.user);
  if (!user || !localStorage.getItem("jwtoken")) return <Navigate to="/login" replace />;
  return C;
}
