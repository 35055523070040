import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import client from "../../utils/Client";
import PublicProfile from "../PublicProfile/PublicProfile";
import UserPannel from "../UserPannel/UserPannel";

const User = () => {
  const roll = useParams().roll;
  const user = useSelector((state) => state.user);
  if (user && user.roll === roll) return <UserPannel />;
  return <PublicProfile roll={roll} />;
};

export default User;
