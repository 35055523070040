import React from "react";
import Header from "../../components/Header/Header";
import Events from "./Events";
import About from "./About";
import LandingPage from "./LandingPage";
import "./style.scss";
import Team from "./Team";
import Achivements from "./Achivements";
import Footer from "../../components/Footer/Footer";
const Home = () => {
  return (
    <>
      <Header />
      <div className="Homepage">
        <LandingPage />
        <About />
        <Achivements />
        <Team />
        <Events />
        <Footer />
      </div>
    </>
  );
};

export default Home;
