import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from "../types";

export const open_snackbar = (data) => ({
  type: OPEN_SNACKBAR,
  payload: { ...data },
});

export const close_snackbar = () => ({
  type: CLOSE_SNACKBAR,
});
