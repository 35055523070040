const options = [
  {
    text: "Create New Contest",
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 25.1C23.4333 25.1 23.7917 24.9583 24.075 24.675C24.3583 24.3917 24.5 24.0333 24.5 23.6V18.5H29.6C30.0333 18.5 30.3917 18.3583 30.675 18.075C30.9583 17.7917 31.1 17.4333 31.1 17C31.1 16.5667 30.9583 16.2083 30.675 15.925C30.3917 15.6417 30.0333 15.5 29.6 15.5H24.5V10.4C24.5 9.96667 24.3583 9.60833 24.075 9.325C23.7917 9.04167 23.4333 8.9 23 8.9C22.5667 8.9 22.2083 9.04167 21.925 9.325C21.6417 9.60833 21.5 9.96667 21.5 10.4V15.5H16.4C15.9667 15.5 15.6083 15.6417 15.325 15.925C15.0417 16.2083 14.9 16.5667 14.9 17C14.9 17.4333 15.0417 17.7917 15.325 18.075C15.6083 18.3583 15.9667 18.5 16.4 18.5H21.5V23.6C21.5 24.0333 21.6417 24.3917 21.925 24.675C22.2083 24.9583 22.5667 25.1 23 25.1ZM9 34C8.2 34 7.5 33.7 6.9 33.1C6.3 32.5 6 31.8 6 31V3C6 2.2 6.3 1.5 6.9 0.9C7.5 0.3 8.2 0 9 0H37C37.8 0 38.5 0.3 39.1 0.9C39.7 1.5 40 2.2 40 3V31C40 31.8 39.7 32.5 39.1 33.1C38.5 33.7 37.8 34 37 34H9ZM3 40C2.2 40 1.5 39.7 0.9 39.1C0.3 38.5 0 37.8 0 37V7.5C0 7.06667 0.141667 6.70833 0.425 6.425C0.708333 6.14167 1.06667 6 1.5 6C1.93333 6 2.29167 6.14167 2.575 6.425C2.85833 6.70833 3 7.06667 3 7.5V37H32.5C32.9333 37 33.2917 37.1417 33.575 37.425C33.8583 37.7083 34 38.0667 34 38.5C34 38.9333 33.8583 39.2917 33.575 39.575C33.2917 39.8583 32.9333 40 32.5 40H3Z"
          fill="#222222"
        />
      </svg>
    ),
    tab: "create-new-contest",
    default: true,
  },
  {
    text: "Approve New User",
    icon: (
      <svg
        width="40"
        height="32"
        viewBox="0 0 40 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.25 31.3C27.05 31.3 26.8667 31.2667 26.7 31.2C26.5333 31.1333 26.3667 31.0167 26.2 30.85L21.25 25.9C20.95 25.6 20.8 25.25 20.8 24.85C20.8 24.45 20.95 24.1 21.25 23.8C21.55 23.5 21.9 23.35 22.3 23.35C22.7 23.35 23.05 23.5 23.35 23.8L27.25 27.7L36.85 18.1C37.15 17.8 37.5 17.65 37.9 17.65C38.3 17.65 38.65 17.8 38.95 18.1C39.25 18.4 39.4 18.75 39.4 19.15C39.4 19.55 39.25 19.9 38.95 20.2L28.3 30.85C28.1333 31.0167 27.9667 31.1333 27.8 31.2C27.6333 31.2667 27.45 31.3 27.25 31.3ZM16 15C13.8 15 12 14.3 10.6 12.9C9.2 11.5 8.5 9.7 8.5 7.5C8.5 5.3 9.2 3.5 10.6 2.1C12 0.7 13.8 0 16 0C18.2 0 20 0.7 21.4 2.1C22.8 3.5 23.5 5.3 23.5 7.5C23.5 9.7 22.8 11.5 21.4 12.9C20 14.3 18.2 15 16 15ZM1.5 31.1C1.06667 31.1 0.708333 30.9583 0.425 30.675C0.141667 30.3917 0 30.0333 0 29.6V26.4C0 25.2667 0.283333 24.225 0.85 23.275C1.41667 22.325 2.26667 21.6 3.4 21.1C5.8 20.0333 8 19.2667 10 18.8C12 18.3333 14 18.1 16 18.1C16.9667 18.1 17.9917 18.1583 19.075 18.275C20.1583 18.3917 21.2667 18.6 22.4 18.9L18.2 23.1C17.6333 23.6667 17.35 24.375 17.35 25.225C17.35 26.075 17.6333 26.7833 18.2 27.35L21.95 31.1H1.5Z"
          fill="white"
        />
      </svg>
    ),
    tab: "approve-new-user",
    default: true,
  },
  {
    text: "All members",
    icon: (
      <svg
        width="48"
        height="24"
        viewBox="0 0 48 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 24C1.06667 24 0.708333 23.8583 0.425 23.575C0.141667 23.2917 0 22.9333 0 22.5V21.35C0 20.05 0.691667 19 2.075 18.2C3.45833 17.4 5.26667 17 7.5 17C7.9 17 8.29167 17.0083 8.675 17.025C9.05833 17.0417 9.43333 17.0833 9.8 17.15C9.53333 17.7167 9.33333 18.3 9.2 18.9C9.06667 19.5 9 20.1333 9 20.8V24H1.5ZM13.5 24C13.0667 24 12.7083 23.8583 12.425 23.575C12.1417 23.2917 12 22.9333 12 22.5V20.8C12 18.6333 13.1083 16.875 15.325 15.525C17.5417 14.175 20.4333 13.5 24 13.5C27.6 13.5 30.5 14.175 32.7 15.525C34.9 16.875 36 18.6333 36 20.8V22.5C36 22.9333 35.8583 23.2917 35.575 23.575C35.2917 23.8583 34.9333 24 34.5 24H13.5ZM39 24V20.8C39 20.1333 38.9333 19.5 38.8 18.9C38.6667 18.3 38.4667 17.7167 38.2 17.15C38.5667 17.0833 38.9417 17.0417 39.325 17.025C39.7083 17.0083 40.1 17 40.5 17C42.7333 17 44.5417 17.4 45.925 18.2C47.3083 19 48 20.05 48 21.35V22.5C48 22.9333 47.8583 23.2917 47.575 23.575C47.2917 23.8583 46.9333 24 46.5 24H39ZM7.5 15.5C6.53333 15.5 5.70833 15.1583 5.025 14.475C4.34167 13.7917 4 12.9667 4 12C4 11.0333 4.34167 10.2083 5.025 9.525C5.70833 8.84167 6.53333 8.5 7.5 8.5C8.46667 8.5 9.29167 8.84167 9.975 9.525C10.6583 10.2083 11 11.0333 11 12C11 12.9667 10.6583 13.7917 9.975 14.475C9.29167 15.1583 8.46667 15.5 7.5 15.5ZM40.5 15.5C39.5333 15.5 38.7083 15.1583 38.025 14.475C37.3417 13.7917 37 12.9667 37 12C37 11.0333 37.3417 10.2083 38.025 9.525C38.7083 8.84167 39.5333 8.5 40.5 8.5C41.4667 8.5 42.2917 8.84167 42.975 9.525C43.6583 10.2083 44 11.0333 44 12C44 12.9667 43.6583 13.7917 42.975 14.475C42.2917 15.1583 41.4667 15.5 40.5 15.5ZM24 12C22.3333 12 20.9167 11.4167 19.75 10.25C18.5833 9.08333 18 7.66667 18 6C18 4.33333 18.5833 2.91667 19.75 1.75C20.9167 0.583333 22.3333 0 24 0C25.6667 0 27.0833 0.583333 28.25 1.75C29.4167 2.91667 30 4.33333 30 6C30 7.66667 29.4167 9.08333 28.25 10.25C27.0833 11.4167 25.6667 12 24 12Z"
          fill="white"
        />
      </svg>
    ),
    tab: "all-members",
    default: true,
  },
  {
    text: "Create Event",
    icon: (
      <svg
        width="41"
        height="42"
        viewBox="0 0 41 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 40C2.2 40 1.5 39.7 0.9 39.1C0.3 38.5 0 37.8 0 37V6C0 5.2 0.3 4.5 0.9 3.9C1.5 3.3 2.2 3 3 3H6.25V1.6C6.25 1.16667 6.40833 0.791667 6.725 0.475C7.04167 0.158333 7.41667 0 7.85 0C8.31667 0 8.70833 0.158333 9.025 0.475C9.34167 0.791667 9.5 1.16667 9.5 1.6V3H26.5V1.6C26.5 1.16667 26.6583 0.791667 26.975 0.475C27.2917 0.158333 27.6667 0 28.1 0C28.5667 0 28.9583 0.158333 29.275 0.475C29.5917 0.791667 29.75 1.16667 29.75 1.6V3H33C33.8 3 34.5 3.3 35.1 3.9C35.7 4.5 36 5.2 36 6V21.05H33V15.5H3V37H18.95V40H3ZM38.45 29.05L34.9 25.5L36.35 24.05C36.6167 23.7833 36.9667 23.65 37.4 23.65C37.8333 23.65 38.1833 23.7833 38.45 24.05L39.9 25.5C40.1667 25.7667 40.3 26.1167 40.3 26.55C40.3 26.9833 40.1667 27.3333 39.9 27.6L38.45 29.05ZM21.95 41.25V39.05C21.95 38.85 21.9833 38.6667 22.05 38.5C22.1167 38.3333 22.2333 38.1667 22.4 38L32.75 27.65L36.3 31.2L25.95 41.55C25.7833 41.7167 25.6167 41.8333 25.45 41.9C25.2833 41.9667 25.1 42 24.9 42H22.7C22.5 42 22.325 41.925 22.175 41.775C22.025 41.625 21.95 41.45 21.95 41.25Z"
          fill="white"
        />
      </svg>
    ),
    tab: "create-event",
    default: true,
  },
  {
    text: "Achievements",
    icon: (
      <svg
        width="67"
        height="60"
        viewBox="0 0 67 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M63.6941 7.40489H51.6938V2.77683C51.6938 1.23801 50.4591 0 48.9245 0H17.539C16.0043 0 14.7696 1.23801 14.7696 2.77683V7.40489H2.76931C1.23465 7.40489 0 8.6429 0 10.1817V16.661C0 20.7915 2.59623 25.0378 7.14251 28.3121C10.7772 30.9386 15.1966 32.6047 19.8352 33.1369C23.4584 39.1649 27.6931 41.6525 27.6931 41.6525V49.983H22.1545C18.0813 49.983 14.7696 52.378 14.7696 56.4623V57.8507C14.7696 58.6143 15.3927 59.2391 16.1543 59.2391H50.3091C51.0707 59.2391 51.6938 58.6143 51.6938 57.8507V56.4623C51.6938 52.378 48.3821 49.983 44.3089 49.983H38.7703V41.6525C38.7703 41.6525 43.0051 39.1649 46.6282 33.1369C51.2784 32.6047 55.6977 30.9386 59.3209 28.3121C63.8557 25.0378 66.4634 20.7915 66.4634 16.661V10.1817C66.4634 8.6429 65.2288 7.40489 63.6941 7.40489ZM11.458 22.3072C8.64255 20.2709 7.38482 18.0031 7.38482 16.661V14.8098H14.7927C14.9081 18.5816 15.462 21.8907 16.2697 24.7832C14.5273 24.1816 12.9004 23.3486 11.458 22.3072ZM59.0786 16.661C59.0786 18.5238 57.0362 20.8378 55.0054 22.3072C53.5631 23.3486 51.9245 24.1816 50.1822 24.7832C50.9899 21.8907 51.5438 18.5816 51.6592 14.8098H59.0786V16.661Z"
          fill="#202020"
        />
      </svg>
    ),
    tab: "achivements",
  },
  {
    text: "Update Membership",
    icon: (
      <svg
        width="41"
        height="42"
        viewBox="0 0 41 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 40C2.2 40 1.5 39.7 0.9 39.1C0.3 38.5 0 37.8 0 37V6C0 5.2 0.3 4.5 0.9 3.9C1.5 3.3 2.2 3 3 3H6.25V1.6C6.25 1.16667 6.40833 0.791667 6.725 0.475C7.04167 0.158333 7.41667 0 7.85 0C8.31667 0 8.70833 0.158333 9.025 0.475C9.34167 0.791667 9.5 1.16667 9.5 1.6V3H26.5V1.6C26.5 1.16667 26.6583 0.791667 26.975 0.475C27.2917 0.158333 27.6667 0 28.1 0C28.5667 0 28.9583 0.158333 29.275 0.475C29.5917 0.791667 29.75 1.16667 29.75 1.6V3H33C33.8 3 34.5 3.3 35.1 3.9C35.7 4.5 36 5.2 36 6V21.05H33V15.5H3V37H18.95V40H3ZM38.45 29.05L34.9 25.5L36.35 24.05C36.6167 23.7833 36.9667 23.65 37.4 23.65C37.8333 23.65 38.1833 23.7833 38.45 24.05L39.9 25.5C40.1667 25.7667 40.3 26.1167 40.3 26.55C40.3 26.9833 40.1667 27.3333 39.9 27.6L38.45 29.05ZM21.95 41.25V39.05C21.95 38.85 21.9833 38.6667 22.05 38.5C22.1167 38.3333 22.2333 38.1667 22.4 38L32.75 27.65L36.3 31.2L25.95 41.55C25.7833 41.7167 25.6167 41.8333 25.45 41.9C25.2833 41.9667 25.1 42 24.9 42H22.7C22.5 42 22.325 41.925 22.175 41.775C22.025 41.625 21.95 41.45 21.95 41.25Z"
          fill="white"
        />
      </svg>
    ),
    tab: "update-membership",
    default: true,
  },
];

export default options;
