import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import client from "../../utils/Client";
import getTime from "../../utils/getTime";
import "./style.scss";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Footer from "../../components/Footer/Footer";

const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const Contest = () => {
  const [contestsData, setContestsData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [open_d, setopen_d] = useState(false);
  const [openDropDow, setOpenDropDown] = useState(null);
  const [openForDelete, setOpenForDelete] = useState(null);
  const fetchAllContest = async () => {
    dispatch(open_loader());
    client.get("/contest").then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        let temp = res.data.contest;
        temp.sort((a, b) => {
          return getTime(b.date, b.time) - getTime(a.date, a.time);
        });
        setContestsData([...res.data.contest]);
      }
    });
  };
  useEffect(() => {
    fetchAllContest();
  }, []);

  const updateFinished = (id, i) => {
    dispatch(open_loader());
    client.post("/contest/" + id + "/finished").then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        let temp = contestsData;
        temp[i].finished = !temp[i].finished;
        setContestsData([...temp]);
      }
      dispatch(
        open_snackbar({
          type: res.data.status ? "success" : "error",
          message: res.data.message,
        })
      );
    });
  };

  const _getDate = (date) => {
    date = new Date(date);
    return {
      mon: months[date.getMonth()],
      date: date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
    };
  };

  const deleteContest = () => {
    dispatch(open_loader());
    setopen_d(false);
    client.delete("/contest/" + openForDelete).then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        let temp = contestsData;
        temp = temp.filter((item) => item._id !== openForDelete);
        setContestsData([...temp]);
        dispatch(
          open_snackbar({
            type: "success",
            message: "contest deleted",
          })
        );
      } else {
        dispatch(
          open_snackbar({
            type: "error",
            message: res.data.status,
          })
        );
      }
    });
  };

  return (
    <>
      <Header />
      <Dialog
        open={open_d}
        onClose={() => setopen_d(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this item?"}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={() => setopen_d(false)}>Cancel</Button>
          <Button onClick={deleteContest} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div className="cntest">
        <h1>Contests</h1>
        <div className="all-contests">
        {contestsData.length===0 && <p className="empty-div">No contest is available now</p>}

          {contestsData.map((item, key) => (
            <div key={key} className="single-contest">
              <div
                style={{
                  backgroundImage: `url(/Assets/black-line-long.svg)`,
                }}
                className="date"
              >
                <div className="dat">{_getDate(item.date).date}</div>
                <div className="mon">{_getDate(item.date).mon}</div>
              </div>
              <div className="banner">
                <img
                  src={
                    item.banner
                      ? item && item.banner && item.banner[0] === "/"
                        ? item.banner.slice(1)
                        : item.banner
                      : "/Assets/default.event.svg"
                  }
                  alt="i-mage"
                />
              </div>
              <div className="event-details">
                <div className="name">{item.name}</div>
                <div className="times">
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.7431 24.6557L24.6141 22.7846L18.0032 16.1322V7.77505H15.5085V17.1301L22.7431 24.6557ZM16.6311 33.2623C14.3582 33.2623 12.21 32.8257 10.1866 31.9526C8.16311 31.0794 6.39606 29.8875 4.88539 28.3769C3.37473 26.8662 2.18284 25.0991 1.3097 23.0757C0.436567 21.0522 0 18.9041 0 16.6311C0 14.3582 0.436567 12.21 1.3097 10.1866C2.18284 8.16311 3.37473 6.39606 4.88539 4.88539C6.39606 3.37473 8.16311 2.18284 10.1866 1.3097C12.21 0.436567 14.3582 0 16.6311 0C18.9041 0 21.0522 0.436567 23.0757 1.3097C25.0991 2.18284 26.8662 3.37473 28.3769 4.88539C29.8875 6.39606 31.0794 8.16311 31.9526 10.1866C32.8257 12.21 33.2623 14.3582 33.2623 16.6311C33.2623 18.9041 32.8257 21.0522 31.9526 23.0757C31.0794 25.0991 29.8875 26.8662 28.3769 28.3769C26.8662 29.8875 25.0991 31.0794 23.0757 31.9526C21.0522 32.8257 18.9041 33.2623 16.6311 33.2623Z"
                      fill="#202020"
                    />
                  </svg>
                  <div className="time">{item.time}</div>
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.1983 28.2729C20.4413 28.2729 23.1924 27.1434 25.4515 24.8843C27.7105 22.6253 28.8401 19.8742 28.8401 16.6311C28.8401 13.3881 27.7105 10.637 25.4515 8.37793C23.1924 6.11887 20.4413 4.98934 17.1983 4.98934V16.6311L9.2569 24.9467C10.2825 26.0277 11.4882 26.8523 12.8742 27.4206C14.2601 27.9888 15.7015 28.2729 17.1983 28.2729ZM17.1983 33.2623C14.9253 33.2623 12.7772 32.8257 10.7537 31.9526C8.73025 31.0794 6.96319 29.8875 5.45253 28.3769C3.94187 26.8662 2.74997 25.0991 1.87684 23.0757C1.00371 21.0522 0.567139 18.9041 0.567139 16.6311C0.567139 14.3305 1.00371 12.1684 1.87684 10.145C2.74997 8.12154 3.94187 6.36141 5.45253 4.86461C6.96319 3.3678 8.73025 2.18284 10.7537 1.3097C12.7772 0.436567 14.9253 0 17.1983 0C19.4989 0 21.661 0.436567 23.6844 1.3097C25.7079 2.18284 27.468 3.3678 28.9648 4.86461C30.4616 6.36141 31.6466 8.12154 32.5197 10.145C33.3928 12.1684 33.8294 14.3305 33.8294 16.6311C33.8294 18.9041 33.3928 21.0522 32.5197 23.0757C31.6466 25.0991 30.4616 26.8662 28.9648 28.3769C27.468 29.8875 25.7079 31.0794 23.6844 31.9526C21.661 32.8257 19.4989 33.2623 17.1983 33.2623Z"
                      fill="#202020"
                    />
                  </svg>
                  <div className="duration">{item.duration}</div>
                </div>
              </div>
              <button
                onClick={() => navigate("/contest/" + item.slug)}
                className="see-details"
              >
                see details
              </button>
              {item.finished === true && (
                <div className="finished-tag">Finished</div>
              )}
              {user?.role === "admin" && (
                <>
                  <button
                    onClick={() =>
                      setOpenDropDown(openDropDow === key ? null : key)
                    }
                    className="see-details menu"
                  >
                    <BiDotsVerticalRounded />
                  </button>
                  {openDropDow === key && (
                    <EditDeleteDropDown
                      id={item._id}
                      setopen_d={setopen_d}
                      setOpenForDelete={setOpenForDelete}
                      setOpenDrop={setOpenDropDown}
                      slug={item.slug}
                      updateFinished={() => updateFinished(item._id, key)}
                      finished={item.finished}
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contest;

const EditDeleteDropDown = ({
  id,
  setOpenForDelete,
  setopen_d,
  setOpenDrop,
  slug,
  updateFinished,
  finished,
}) => {
  return (
    <div className="edit-delete drop-down">
      <Link
        to={"/admin?Tab=edit-contest&contest-slug=" + slug}
        className="edit"
      >
        Edit
      </Link>
      <div
        onClick={() => {
          setOpenForDelete(id);
          setopen_d(true);
          setOpenDrop(null);
        }}
        className="delete"
      >
        Delete
      </div>
      <div
        onClick={updateFinished}
        className="delete"
        style={{
          fontSize: 14,
        }}
      >
        finished
        <div className={`${finished ? "finished" : "not-finished"}`}></div>
      </div>
    </div>
  );
};
