import React, { useState } from "react";
import { SiGoogleclassroom } from "react-icons/si";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useRef } from "react";
import { useEffect } from "react";
import client from "../../utils/Client";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import AddNewCourse from "./AddNewCourse";
import { useNavigate } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
const Course = ({ item, fetchCourses }) => {
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const ref = useRef();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (ref && ref.current) {
      document.body.addEventListener("click", (e) => {
        if (
          ref &&
          ref.current !== undefined &&
          !ref.current.contains(e.target)
        ) {
          setOpen(false);
        }
      });
    }
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(open_loader());
    client.delete("/courses/" + item._id).then((res) => {
      dispatch(close_loader());
      if (res.data.status === true) {
        fetchCourses();
        dispatch(
          open_snackbar({
            type: res.data.status ? "success" : "error",
            message: res.data.message,
          })
        );
      }
    });
  };

  return (
    <div className="course">
      <AddNewCourse
        fetchCourses={fetchCourses}
        setEditOpen={setEditOpen}
        item={item}
        update={true}
        editOpen={editOpen}
      />
      <div className="states">
        {item.finished === true ? (
          <div className="state finished">Finished</div>
        ) : item.running == true ? (
          <div className="state running">running</div>
        ) : (
          <div className="state upcoming">upcoming</div>
        )}
        {user !== null && user.role === "admin" && (
          <div ref={ref} className="drop-down">
            <button onClick={() => setOpen(!open)}>
              <BsThreeDotsVertical />
            </button>
            {open === true && (
              <div className="drop-down-items">
                <div onClick={() => setEditOpen(true)} className="item">
                  Edit
                </div>
                <div onClick={() => setAlertOpen(true)} className="item">
                  Delete
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <h1>{item.name}</h1>
      <p>Fee : {item.fee === 0 ? "Free" : item.fee + "/-"}</p>
      <div className="abx">
        <div>
          <SiGoogleclassroom />
        </div>{" "}
        offline class
      </div>
      <div className="organize-by">
        <p className="oo">Organized by</p>
        <p className="vanue">Programming Club Of IST</p>
        <button onClick={() => navigate("/course/" + item.slug)}>
          <BsFillArrowRightCircleFill />
        </button>
      </div>
      <div>
        <Dialog
          style={{
            zIndex: 1000,
          }}
          open={alertOpen}
          onClose={() => setAlertOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete course"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this course?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAlertOpen(false)}>Cancel</Button>
            <Button onClick={handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Course;
