import { Alert, MenuItem, Select, Snackbar, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import cappitalize from "../../utils/cappitalize";
import client from "../../utils/Client";
const allPositions = [
  "Faculty Member",
  "General Secretary",
  "Joint Secretary",
  "Organizing Secretary",
  "Executive Member",
  "Member",
];
const Role = ["admin", "public"];
const AllMembers = () => {
  const [users, setUsers] = useState([]);
  const [openuser, setopenuser] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const perPage = 10;
  let cancelSearch = new AbortController();
  const search = () => {
    if (cancelSearch) cancelSearch.abort();
    cancelSearch = new AbortController();
    client
      .post("/user/all-users", {
        start: (page - 1) * perPage,
        limit: perPage,
        roll: searchText,
        signal: cancelSearch.signal,
      })
      .then((res) => {
        setopenuser(null);
        if (res.data.status) {
          setUsers([...res.data.users]);
          setTotal(res.data.total);
        }
      })
      .catch(() => {
        console.log("searching...");
      });
  };
  useEffect(() => {
    search();
  }, [searchText, page]);

  return (
    <div
      style={{
        paddingBottom: 100,
      }}
      className="approve-new-user"
    >
      <div className="header-xy">
        <div className="title">Approve New User</div>
        <div className="search-box">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M33.8 36L20.65 22.85C19.65 23.7167 18.4833 24.3917 17.15 24.875C15.8167 25.3583 14.4 25.6 12.9 25.6C9.3 25.6 6.25 24.35 3.75 21.85C1.25 19.35 0 16.3333 0 12.8C0 9.26667 1.25 6.25 3.75 3.75C6.25 1.25 9.28333 0 12.85 0C16.3833 0 19.3917 1.25 21.875 3.75C24.3583 6.25 25.6 9.26667 25.6 12.8C25.6 14.2333 25.3667 15.6167 24.9 16.95C24.4333 18.2833 23.7333 19.5333 22.8 20.7L36 33.8L33.8 36ZM12.85 22.6C15.55 22.6 17.85 21.6417 19.75 19.725C21.65 17.8083 22.6 15.5 22.6 12.8C22.6 10.1 21.65 7.79167 19.75 5.875C17.85 3.95833 15.55 3 12.85 3C10.1167 3 7.79167 3.95833 5.875 5.875C3.95833 7.79167 3 10.1 3 12.8C3 15.5 3.95833 17.8083 5.875 19.725C7.79167 21.6417 10.1167 22.6 12.85 22.6Z"
              fill="black"
            />
          </svg>

          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="roll id"
          />
        </div>
      </div>
      <div className="all-users">
        {users.map((item, i) => (
          <div key={i}>
            <div
              onClick={() => {
                if (openuser === i) setopenuser(null);
                else setopenuser(i);
              }}
              style={{
                justifyContent: "flex-start",
                padding: "3px",
                cursor: "pointer",
                borderRadius: 4,
              }}
              className={`single-rank${openuser === i ? " opened-box" : ""}`}
            >
              <div className="picture">
                <img src={item.profilePic} alt="profile-picture" />
              </div>
              <div className="left-side">
                <div style={{ fontSize: 18 }} className="team-name">
                  {item.name}
                </div>
                <div
                  style={{
                    textTransform: "capitalize",
                  }}
                  className="team-position"
                >
                  {item.position}
                </div>
              </div>
              {/* <div className="right-side">
                <button style={{display : "none"}}></button>
                <button className="goto-links-button">
                  <img src="Assets/goto_link.svg" />
                </button>
              </div> */}
            </div>
            {openuser === i && <OpenDetails search={search} _user={item} />}
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination
          page={page}
          onChange={(e, value) => setPage(value)}
          count={Math.ceil(total / perPage)}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
};

const OpenDetails = ({ _user, search }) => {
  const [user, setUser] = useState(_user);
  const dispatch = useDispatch();
  const [SnackbarOpen, setSnackbarOpen] = useState(false);
  const [snakbartext, setsnackbartext] = useState({
    message: "",
    type: "error",
  });
  const update_position = () => {
    if (user !== _user) {
      dispatch(open_loader());
      client
        .post("/usermanagement/updateuser", {
          roll: user.roll,
          role: user.role,
          position: user.position,
        })
        .then((res) => {
          search();
          dispatch(close_loader());
          if (res.data.status) {
            setsnackbartext({
              message: res.data.message,
              type: "success",
            });
            setSnackbarOpen(true);
          } else {
            setsnackbartext({
              message: res.data.message,
              type: "error",
            });
            setSnackbarOpen(true);
          }
        });
    }
  };
  return (
    <div className="open-details">
      <Snackbar
        open={SnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snakbartext.type}
          sx={{ width: "100%" }}
        >
          {snakbartext.message}
        </Alert>
      </Snackbar>
      <p className="half-bold">Name</p>
      <input className="input-1" readOnly value={user.name} />
      <div className="single-line">
        <div className="left-side">
          <p className="half-bold">Roll</p>
          <input className="input-1" readOnly value={user.roll} />
        </div>
        <div className="right-side">
          <p className="half-bold">Batch</p>
          <input className="input-1" readOnly value={user.batch} />
        </div>
      </div>
      <div className="single-line">
        <div className="left-side">
          <p className="half-bold">Department</p>
          <input className="input-1" readOnly value={user.dept} />
        </div>
        <div className="right-side">
          <p className="half-bold">Position</p>
          <Select
            value={cappitalize(user.position)}
            onChange={(e) => setUser({ ...user, position: e.target.value })}
            inputProps={{ "aria-label": "Without label" }}
            className="input-1 drop-down"
          >
            {allPositions.map((item, i) => (
              <MenuItem selected={item === user.position} key={i} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <p className="half-bold">Role</p>
      <Select
        value={user.role}
        onChange={(e) => setUser({ ...user, role: e.target.value })}
        inputProps={{ "aria-label": "Without label" }}
        className="input-1 drop-down"
      >
        {Role.map((item, i) => (
          <MenuItem selected={item === user.role} key={i} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <button
        style={{
          fontSize: 15,
          float: "unset",
          marginTop: 0,
        }}
        onClick={update_position}
        className="post-button"
      >
        Save changes
      </button>
    </div>
  );
};

export default AllMembers;
