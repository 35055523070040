import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import client from "../../utils/Client";

const UpdateMemberShip = () => {
  const [rolls, setRolls] = useState("");
  const dispatch = useDispatch();
  const [not_updated, setNotUpdated] = useState([]);
  const update = async () => {
    let all_rolls = rolls.split(",");
    dispatch(open_loader());
    let temp = [];
    setNotUpdated([]);

    for (let i = 0; i < all_rolls.length; i++) {
      all_rolls[i] = all_rolls[i].replace(" ", "");
      try {
        let data = await client.post("/usermanagement/membershipupdate", {
          roll: all_rolls[i],
        });
        if (!data.data.status) {
          temp.push(all_rolls[i]);
        }
      } catch (err) {
        console.log(err);
      }
    }
    dispatch(close_loader());
    setNotUpdated([...temp]);
    console.log(not_updated);
  };
  return (
    <div>
      <div className="title">
        Update Membership
        <textarea
          className="input-1"
          placeholder="1,2,3,4,5,6,7,8,9,10,11"
          value={rolls}
          onChange={(e) => setRolls(e.target.value)}
          style={{
            height: 100,
            padding: 10,
          }}
        />
      </div>
      <button onClick={update} className="post-button">
        Update all
      </button>
      {not_updated.length>0 && (
        <div
          style={{
            marginTop: 100,
          }}
        >
          <div style={{ fontSize: 14 }} className="title">
            Update problems :{" "}
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            {not_updated.map((item, i) => (
              <p style={{ marginRight: 10 }} key={i}>
                {item}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateMemberShip;
