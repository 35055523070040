import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import client from "../../utils/Client";
import "./style.scss";
import RichTextView from "../../components/RichTextView/RichTextView";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import { DataGrid } from "@mui/x-data-grid";

const CourseDetails = () => {
  const slug = useParams().slug;
  const [contest, setContestDetails] = useState({});
  const dispatch = useDispatch();
  const [contestNotFound, setContestNotFound] = useState(false);
  const [enrolledUsers, setEnrollUsers] = useState([]);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(open_loader());
    client.get("/courses/" + slug).then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        setContestDetails({ ...res.data.course });
        dispatch(open_loader());
        client.get("courses/enroll/" + res.data.course._id).then((res) => {
          dispatch(close_loader());
          if (res.data.status) {
            setEnrollUsers([...res.data.enrolledMember]);
          }
        });
      } else {
        dispatch(close_loader());
        setContestNotFound(true);
      }
    });
  }, []);

  useEffect(() => {}, []);
  if (contestNotFound) {
    return (
      <>
        <Header />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "60vh",
          }}
          className="contest-not-found"
        >
          <h1>Course Not Found</h1>
        </div>
        <Footer />
      </>
    );
  }
  const EnrollCourse = () => {
    client.post("/courses/enroll/" + contest._id).then((res) => {
      dispatch(
        open_snackbar({
          type: res.data.status ? "success" : "error",
          message: res.data.message,
        })
      );
    });
  };

  return (
    <>
      <Header />
      <div className="contest-details-xy">
        <div className="Contest-details">
          {contest.banner && (
            <div className="contest-banner">
              <img src={contest.banner} alt="banner" />
            </div>
          )}
        </div>

        <div className="details">
          <div className="details-side">
            <h1>{contest.name}</h1>
            <div className="states">
              {contest.finished === true ? (
                <div className="state finished">Finished</div>
              ) : contest.running == true ? (
                <div className="state running">running</div>
              ) : (
                <div className="state upcoming">upcoming</div>
              )}
            </div>
            <p>Fee : {contest.fee === 0 ? "Free" : contest.fee + "/-"}</p>
            <p>{contest.reqMembership === true && "Membership required"}</p>
            {contest.technology ? (
              <>
                {" "}
                <p>Technologies:</p>
                <ol
                  style={{
                    listStylePosition: "inside",
                  }}
                >
                  {contest?.technology.map((item, key) => (
                    <li key={key}>{item}</li>
                  ))}
                </ol>{" "}
              </>
            ) : null}
          </div>
        </div>
        <div className="details-section">
          {" "}
          <RichTextView text={contest.details} />
        </div>

        {contest.finished === false && (
          <button onClick={EnrollCourse} className="enroll-button">
            Enroll Now
          </button>
        )}

        {user !== null && user.role === "admin" && (
          <EnrollMembersTable members={enrolledUsers} />
        )}
      </div>
      <Footer />
    </>
  );
};

const EnrollMembersTable = ({ members }) => {
  return (
    <div className="enroll-users">
      <h1>Enrolled users : </h1>
      <ol>
        {members?.map((item, i) => (
          <li key={i}>
            <a href={`/users/${item.roll}`} target={"_blank"}>
              {item.roll}
            </a>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default CourseDetails;
