import React, { useEffect } from "react";
import 'react-quill/dist/quill.bubble.css'
import "./style.scss"
const RichTextView = ({ text }) => {
  return <div className="rich-text-view" dangerouslySetInnerHTML={{__html : text}}>

  </div>
};

export default RichTextView;
