import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from "../types";

const init = {
  open: false,
  type: "",
  message: "",
};

const SnackbarReducer = (state = init, action = {}) => {
  switch (action.type) {
    case CLOSE_SNACKBAR:
      return {
        ...state,
        type: "",
        message: "",
        open: false,
      };
    case OPEN_SNACKBAR:
      return {
        ...state,
        open: true,
        message: action.payload.message,
        type: action.payload.type,
      };
    default:
      return state;
  }
};

export default SnackbarReducer;
