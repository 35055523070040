import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

const NotFound = () => {
  return (
    <>
      <Header />
      <div
        style={{
          minWidth: "100%",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="not-found"
      >
        <h1>Not Found</h1>
      </div>

      <Footer />
    </>
  );
};

export default NotFound;
