import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch } from "react-redux";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import {
  close_snackbar,
  open_snackbar,
} from "../../redux/SnackBar/SnackBarActions";
import client from "../../utils/Client";
import { BACKEND_URL } from "../../utils/config";
import CompressImage, { CompressImageFile } from "../../utils/CompressImage";
const fileTypes = ["JPG", "PNG", "jpg", "jpeg", "png"];

const Achievements = () => {
  const [newAchievement, setNewAchievement] = useState({
    year: "",
    details: "",
    file: "",
  });

  const [allAchievements, setAllachievements] = useState([]);

  const fetchAchievements = () => {
    dispatch(open_loader());
    client.get("/achivements").then((res) => {
      if (res.data.status) {
        dispatch(close_loader());
        setAllachievements([...res.data.achivement]);
      }
    });
  };

  useEffect(() => {
    fetchAchievements();
  }, []);
  const dispatch = useDispatch();
  const handleUploadAchievements = () => {
    let formdata = new FormData();
    formdata.append("file", newAchievement.file);
    formdata.append("year", newAchievement.year);
    formdata.append("details", newAchievement.details);

    client
      .post("/achivements", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch(close_loader());
        if (res.data.status) {
          fetchAchievements();
        }
        dispatch(
          open_snackbar({
            type: res.data.status ? "success" : "error",
            message: res.data.message,
          })
        );
      });
  };
  const handleFileUpload = async (e) => {
    dispatch(open_loader());
    let file = await CompressImageFile(e);
    dispatch(close_loader());
    setNewAchievement({
      ...newAchievement,
      file: file,
    });
  };
  const Delete = (id) => {
    dispatch(open_loader());
    client
      .delete("/achivements/" + id)
      .then((res) => {
        dispatch(close_loader());
        if (res.data.status) {
          dispatch(
            open_snackbar({
              type: "success",
              message: res.data.message,
            })
          );
          fetchAchievements();
        } else {
          open_snackbar({
            type: "error",
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        dispatch(close_loader());
        dispatch(
          open_snackbar({
            type: "error",
            message: "something went wrong",
          })
        );
      });
  };
  return (
    <div className="Achievements-admin-pannel">
      <div className="title">Achievements</div>
      <div className="upload-new">
        <input
          onChange={(e) =>
            setNewAchievement({ ...newAchievement, details: e.target.value })
          }
          placeholder="Details"
          className="input-1"
        />
        <input
          onChange={(e) =>
            setNewAchievement({ ...newAchievement, year: e.target.value })
          }
          placeholder="Year"
          type="number"
          className="input-1"
        />

        <p
          style={{
            margin: 0,
          }}
        >
          Upload Banner Image
        </p>
        {newAchievement.file && (
          <div className="uploaded-image-preview">
            <img
              alt="banner"
              src={
                newAchievement.file
                  ? URL.createObjectURL(newAchievement.file)
                  : " "
              }
            />
          </div>
        )}
        <FileUploader
          children={
            <div className="upload-box">
              <div className="upload-icon">
                <svg
                  width="138"
                  height="100"
                  viewBox="0 0 138 100"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M33.0625 100C24.1042 100 16.3958 96.7708 9.9375 90.3125C3.47917 83.8542 0.25 76.1458 0.25 67.1875C0.25 59.0625 2.82812 51.901 7.98437 45.7031C13.1406 39.5052 19.7813 35.7812 27.9063 34.5312C29.9896 24.4271 34.8854 16.1719 42.5938 9.76562C50.3021 3.35937 59.2083 0.15625 69.3125 0.15625C81.0833 0.15625 90.9531 4.40104 98.9219 12.8906C106.891 21.3802 110.875 31.5625 110.875 43.4375V47.1875C118.375 46.9792 124.729 49.401 129.938 54.4531C135.146 59.5052 137.75 65.8854 137.75 73.5938C137.75 80.7812 135.146 86.9792 129.938 92.1875C124.729 97.3958 118.531 100 111.344 100H73.6875C71.1875 100 69 99.0625 67.125 97.1875C65.25 95.3125 64.3125 93.125 64.3125 90.625V50.3125L51.3438 63.2813L44.625 56.5625L69 32.1875L93.375 56.5625L86.6562 63.2813L73.6875 50.3125V90.625H111.344C116.031 90.625 120.042 88.9583 123.375 85.625C126.708 82.2917 128.375 78.2812 128.375 73.5938C128.375 68.9062 126.708 64.8958 123.375 61.5625C120.042 58.2292 116.031 56.5625 111.344 56.5625H101.5V43.4375C101.5 34.1667 98.349 26.1979 92.0469 19.5312C85.7448 12.8646 77.9583 9.53125 68.6875 9.53125C59.4167 9.53125 51.6042 12.8646 45.25 19.5312C38.8958 26.1979 35.7188 34.1667 35.7188 43.4375H32.75C26.2917 43.4375 20.8229 45.7031 16.3438 50.2344C11.8646 54.7656 9.625 60.3646 9.625 67.0313C9.625 73.4896 11.9167 79.0365 16.5 83.6719C21.0833 88.3073 26.6042 90.625 33.0625 90.625H54.9375V100H33.0625Z"
                    fill="#404040"
                  />
                </svg>
              </div>
              <p>Drag and Drop or browse to choose a file</p>
            </div>
          }
          handleChange={handleFileUpload}
          name="file"
          types={fileTypes}
        />

        <div
          onClick={handleUploadAchievements}
          style={{ width: "200px", fontSize: 16 }}
          className="post-button"
        >
          Add Achievement
        </div>
      </div>
      <div className="all-achievements">
        {allAchievements.map((item, i) => (
          <div key={i} className="achivement-item">
            <img src={item.path} />
            <div className="details-sec">
              <h1>{item.year}</h1>
              <p>{item.details}</p>
              <button onClick={() => Delete(item._id)}>delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Achievements;
