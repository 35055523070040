import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import client from "../../utils/Client";
import { BACKEND_URL } from "../../utils/config";
const _getDate = (value) => {
  let date = new Date(value);
  date =
    String(date.getFullYear()) +
    "-" +
    (String(date.getMonth() + 1).length === 1
      ? "0" + String(date.getMonth() + 1)
      : String(date.getMonth() + 1)) +
    "-" +
    (String(date.getDate()).length === 1
      ? "0" + String(date.getDate())
      : String(date.getDate()));
  return date;
};
const EditContest = ({ slug, setSlug }) => {
  const [contest, setContest] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    client.get("/contest/" + slug).then((res) => {
      console.log(res.data);
      if (res.data.status) {
        setContest({
          ...res.data.contest,
          date: _getDate(res.data.contest.date),
          deadline: _getDate(res.data.contest.deadline),
        });
      }
    });
  }, [slug]);
  const save = () => {
    dispatch(open_loader());
    let formdate = new FormData();
    Object.keys(contest).forEach((item) => {
      if (item !== null) formdate.append(item, contest[item]);
    });

    client.put("/contest/" + contest._id, formdate).then((res) => {
      dispatch(
        open_snackbar({
          type: res.data.status ? "success" : "error",
          message: res.data.message,
        })
      );
      dispatch(close_loader());
    });
  };
  if (!slug || slug.length === 0 || !contest) return <div>{slug}</div>;
  return (
    <div className="contest-edit">
      <p>Contest slug</p>
      <input className="slug" readOnly value={slug} />
      <p>Name : </p>
      <input
        className="name"
        value={contest.name}
        onChange={(e) => setContest({ ...contest, name: e.target.value })}
      />
      <p>Date : </p>
      <input
        type={"date"}
        value={contest.date}
        onChange={(e) => setContest({ ...contest, date: e.target.value })}
      />
      <p>Time : </p>
      <input
        type={"time"}
        value={contest.time}
        onChange={(e) => setContest({ ...contest, time: e.target.value })}
      />
      <p>duration : </p>
      <input
        type={"text"}
        value={contest.duration}
        onChange={(e) => setContest({ ...contest, duration: e.target.value })}
      />
      <p>Type : </p>
      <select
        style={{
          height: 40,
        }}
        onChange={e=>setContest({...contest, type : e.target.value})}
        value={contest.type}
      >
        <option>{"single"}</option>
        <option>{"team"}</option>
      </select>
      <p>Fee : </p>
      <input
        type={"number"}
        value={contest.fee}
        onChange={(e) => setContest({ ...contest, fee: e.target.value })}
      />
      <p>Link : </p>
      <input
        type={"text"}
        value={contest.link}
        onChange={(e) => setContest({ ...contest, link: e.target.value })}
      />
      <p>Banner : </p>
      <input
        type={"file"}
        onChange={(e) => setContest({ ...contest, file: e.target.files[0] })}
      />
      <p>Date : </p>
      <input
        type={"date"}
        value={contest.deadline}
        onChange={(e) => setContest({ ...contest, deadline: e.target.value })}
      />
      <p>Banner preview : </p>
      <img
        src={
          contest.file
            ? URL.createObjectURL(contest.file)
            : contest.banner
        }
      />
      <button onClick={save}>Save Changes</button>
    </div>
  );
};

export default EditContest;
