import React from "react";
import { useDispatch } from "react-redux";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";

const RegistrationDone = ({ registrationId }) => {
  const dispath = useDispatch();
  return (
    <div className="registration-done">
      <img src="Assets/done-icon.svg" alt="i-mage" className="done-sign" />
      <p>We have recieve your request</p>
      <p>
        Please don't forget to inform our committee of your registration id{" "}
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigator.clipboard.writeText(registrationId).then(() => {
              dispath(
                open_snackbar({
                  type: "success",
                  message: "copied!",
                })
              );
            });
          }}
        >
          ( {registrationId} )
        </span>
      </p>
    </div>
  );
};

export default RegistrationDone;
