import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import client from "../../utils/Client";
import AddNewCourse from "./AddNewCourse";

import "./style.scss";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import Course from "./Course";
const Courses = () => {
  const user = useSelector((state) => state.user);
  const [courses, setCourses] = useState([]);
  const dispatch = useDispatch();
  const fetchCourses = () => {
    dispatch(open_loader());
    client.get("/courses").then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        setCourses([...res.data.course]);
      }
    });
  };
  useEffect(() => {
    fetchCourses();
  }, []);
  return (
    <>
      <Header />
      <div className="courses">
        <div className="title">
          <h1>Courses</h1>
          <AddNewCourse fetchCourses={fetchCourses} />
        </div>
        <div className="all-courses">
          {courses.map((item,key) => (
            <Course key={key} item={item} fetchCourses={fetchCourses} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Courses;
