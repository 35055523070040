import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import client from "../../utils/Client";
import { BACKEND_URL } from "../../utils/config";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import "./style.scss";
import CountDown from "../../components/CountDown/CountDown";
import getTime from "../../utils/getTime";
import Standings from "./Standings";
const months = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];
const ContestDetails = () => {
  const slug = useParams().slug;
  const [contest, setContestDetails] = useState({});
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [teamname, setteamname] = useState("");
  const [pendingRequests, setPendingRequests] = useState([]);
  const [teamDetails, setTeamDetails] = useState({
    first: {
      roll: user?.roll,
      name: user?.name,
      batch: user?.batch,
      dept: user?.dept,
    },
    second: {
      roll: "",
      name: "",
      batch: "",
      dept: "",
    },
    third: {
      roll: "",
      name: "",
      batch: "",
      dept: "",
    },
  });
  const [contestNotFound, setContestNotFound] = useState(false);
  const [myTeam, setmyteam] = useState(null);
  useEffect(() => {
    dispatch(open_loader());
    client.get("/contest/" + slug).then((res) => {
      if (res.data.status) {
        setContestDetails({ ...res.data.contest });
        fetchMyTeam(res.data.contest._id);
      } else {
        dispatch(close_loader());
        setContestNotFound(true);
      }
    });
  }, []);
  if (contestNotFound) {
    return (
      <>
        <Header />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "60vh",
          }}
          className="contest-not-found"
        >
          <h1>Contest Not Found</h1>
        </div>
        <Footer />
      </>
    );
  }
  const _getDate = (date) => {
    date = new Date(date);
    return {
      mon: months[date.getMonth()],
      date: date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
      year: date.getFullYear(),
    };
  };

  const search_user = (s) => {
    dispatch(open_loader());
    client
      .get("/user/details/" + teamDetails[s].roll)
      .then((res) => {
        dispatch(close_loader());
        if (res.data.status) {
          setTeamDetails({
            ...teamDetails,
            [s]: {
              ...teamDetails[s],
              ...res.data.user,
            },
          });
        } else {
          dispatch(
            open_snackbar({
              type: "error",
              message: "user not found!",
            })
          );
          setTeamDetails({
            ...teamDetails,
            [s]: {
              name: "",
              batch: "",
              dept: "",
            },
          });
        }
      })
      .catch(() => {
        dispatch(close_loader());
      });
  };

  const createNewTeam = async () => {
    if (contest.type === "team" && teamname.substring(0, 4) !== "IST_") {
      dispatch(
        open_snackbar({
          type: "error",
          message: "must use IST_ before team name",
        })
      );
      return;
    }

    let members = {
      first: teamDetails.first.roll,
      second: teamDetails.second.roll,
      third: teamDetails.third.roll,
    };
    if (contest.type === "single") {
      members = {
        first: user.roll,
      };
    }
    client
      .post("/team", {
        contestId: contest._id,
        name: contest.type === "single" ? user.name : teamname,
        members,
      })
      .then((res) => {
        dispatch(
          open_snackbar({
            type: res.data.status ? "success" : "error",
            message: res.data.message,
          })
        );
        fetchMyTeam(contest._id);
      });
  };
  const handleToggleRegistraion = () => {
    dispatch(open_loader());
    client.put("/contest/toggle/" + contest._id).then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        setContestDetails({
          ...contest,
          closed: contest.closed ? false : true,
        });
      }
    });
  };

  const fetchMyTeam = (id) => {
    dispatch(open_loader());
    client
      .post("/team/myteam", { contestId: id })
      .then((res) => {
        if (res.data.status) {
          dispatch(close_loader());
          setmyteam({ ...res.data.details });
        } else {
          setmyteam(null);
          client
            .post("/team/pending/requests", { contestId: id })
            .then((res) => {
              if (res.data.status) {
                dispatch(close_loader());
                setPendingRequests([...res.data.requests]);
              } else dispatch(close_loader());
            })
            .catch((err) => dispatch(close_loader()));
        }
      })
      .catch(() => dispatch(close_loader()));
  };

  const AcceptTeamRequest = (id) => {
    client
      .post("/team/accept", { contestId: contest._id, teamId: id })
      .then((res) => {
        fetchMyTeam(contest._id);
      });
  };
  const leaveTeam = (id) => {
    client
      .post("/team/leave", {
        contestId: contest._id,
        teamId: id,
      })
      .then((res) => {
        if (res.data.status) {
          dispatch(
            open_snackbar({
              message: res.data.message,
              type: "success",
            })
          );
          fetchMyTeam(contest._id);
        } else {
          dispatch(
            open_snackbar({
              message: res.data.message,
              type: "error",
            })
          );
        }
      });
  };
  const deleteTeam = (id) => {
    client.delete("/team/" + id).then((res) => {
      if (res.data.status) {
        dispatch(
          open_snackbar({
            message: res.data.message,
            type: "success",
          })
        );
        fetchMyTeam(contest._id);
      } else {
        dispatch(
          open_snackbar({
            message: res.data.message,
            type: "error",
          })
        );
        fetchMyTeam(contest._id);
      }
    });
  };

  return (
    <>
      <Header />
      <div className="contest-details-xy">
        <div className="Contest-details">
          {contest.banner && (
            <div className="contest-banner">
              <img src={ contest.banner} alt="banner" />
            </div>
          )}
        </div>

        <div className="details">
          <div className="details-side">
            <h1>{contest.name}</h1>
            <div className="date-sec">
              <p>Date : </p>
              <p>{_getDate(contest.date).date}</p>{" "}
              <p>{_getDate(contest.date).mon}</p>
              <p>{_getDate(contest.date).year}</p>
            </div>
            <p>Time : {contest.time}</p>
            <p>Duration : {contest.duration} hours</p>
            <p>Fee : {contest.fee}/-</p>
            <p>Type : {contest.type}</p>
            <p>
              Registration deadline :{" "}
              {_getDate(contest.deadline).date +
                " " +
                _getDate(contest.deadline).mon +
                " " +
                _getDate(contest.deadline).year}
            </p>
            {user && user.role === "admin" && (
              <div className="admin-boxes">
                <Link
                  to={"/contest/" + contest.slug + "/all-teams"}
                  className="all-team-button"
                  href="/all-teams"
                >
                  <button>All teams</button>
                </Link>
                <div className="all-team-button regis">
                  <button onClick={handleToggleRegistraion}>
                    {contest.closed
                      ? "Open Registration"
                      : "Close Registration"}
                  </button>
                </div>
              </div>
            )}
            {contest.closed === true && (
              <p style={{ marginTop: 10 }}>Registration has been closed</p>
            )}
          </div>

          {contest !== null &&
            contest.date != undefined &&
            contest.time != undefined && (
              <div className="contdown-zone">
                <CountDown
                  endTime={getTime(contest.date, contest.time)}
                  className={"count-down"}
                />
              </div>
            )}
        </div>
        {contest !== null &&
          contest.type === "single" &&
          contest.finished === false && (
            <button onClick={createNewTeam} className="register-button">
              {myTeam ? "You have registerd" : "Register Now"}
            </button>
          )}

        {contest !== null &&
          contest.type === "team" &&
          contest.finished === false && (
            <div className="team-section">
              {user &&
                myTeam &&
                (contest.closed === false ||
                  (contest.closed === true && myTeam.approved === true)) && (
                  <>
                    <div className="pending-request">
                      <h1>My Team</h1>
                      <div className="request-item">
                        <div className="team-name">
                          {myTeam.teamName} {`( ${myTeam.createdBy} )`}
                        </div>
                        <div className="team-members">
                          {myTeam.members?.map((item, key) => (
                            <div key={key} className="member">
                              {item}
                            </div>
                          ))}
                          {myTeam.pedingMembers?.map((item, key) => (
                            <div key={key} className="member pending">
                              {item}
                            </div>
                          ))}
                        </div>

                        {contest.closed === false && (
                          <>
                            {user.roll === myTeam.createdBy ? (
                              <button
                                className="ac"
                                onClick={() => deleteTeam(myTeam.teamId)}
                              >
                                Delete
                              </button>
                            ) : (
                              <button
                                className="ac"
                                onClick={() => leaveTeam(myTeam.teamId)}
                              >
                                Leave
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              {!myTeam && user && (
                <>
                  {contest.closed === false && (
                    <div className="registration-now">
                      <h1>Registration Now</h1>
                      <input
                        placeholder="Team name ex: IST_HelloWorld (must use IST_)"
                        value={teamname}
                        onChange={(e) => setteamname(e.target.value)}
                      />
                      {["first", "second", "third"].map((item, i) => (
                        <div key={i} className="members">
                          <h2>{item} Member Details : </h2>
                          <input
                            onChange={(e) =>
                              setTeamDetails({
                                ...teamDetails,
                                [item]: {
                                  ...teamDetails[item],
                                  roll: e.target.value,
                                },
                              })
                            }
                            placeholder="Roll number"
                            value={teamDetails[item].roll}
                            className="input-1"
                            onBlur={() => search_user(item)}
                            readOnly={item === "first"}
                          />
                          <input
                            readOnly
                            value={teamDetails[item].name}
                            className="input-1"
                          />
                          <input
                            readOnly
                            value={teamDetails[item].batch}
                            className="input-1"
                          />
                          <input
                            readOnly
                            value={teamDetails[item].dept}
                            className="input-1"
                          />
                        </div>
                      ))}
                      <button onClick={createNewTeam}>
                        Request other members
                      </button>
                    </div>
                  )}
                  {contest.closed === false &&
                    pendingRequests &&
                    pendingRequests.length > 0 && (
                      <div className="pending-request">
                        <h1>Pending Requests</h1>
                        {pendingRequests.map((item, i) => (
                          <div key={i} className="request-item">
                            <div className="team-name">
                              {item.teamName} {`( ${item.createdBy} )`}
                            </div>
                            <div className="team-members">
                              {item.members?.map((item, i) => (
                                <div key={i} className="member">
                                  {item}
                                </div>
                              ))}
                              {item.pedingMembers?.map((item, i) => (
                                <div key={i} className="member pending">
                                  {item}
                                </div>
                              ))}
                            </div>
                            <button
                              className="ac"
                              onClick={() => AcceptTeamRequest(item.teamId)}
                            >
                              Accept
                            </button>
                            {/* <button>Delete</button> */}
                          </div>
                        ))}
                      </div>
                    )}
                </>
              )}
              {!user && (
                <div>
                  <h1>Please Login to register</h1>
                </div>
              )}
            </div>
          )}
        {contest.finished === true && <Standings id={contest._id} />}
      </div>
      <Footer />
    </>
  );
};

export default ContestDetails;
