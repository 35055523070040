import { combineReducers } from "redux";
import LoaderReducer from "./Loader/LoaderReducer";
import SnackbarReducer from "./SnackBar/SnackBarReducer";
import userReducer from "./user/userReducer";

const rootReducer = combineReducers({
  user: userReducer,
  loader: LoaderReducer,
  snackbar: SnackbarReducer,
});
export default rootReducer;
