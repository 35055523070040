import { Button } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import client from "../../utils/Client";
import generateCertificate from "../../utils/htmltoimg";
import NotFound from "../NotFound/NotFound";
import { BsLinkedin } from "react-icons/bs";
import "./style.scss";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
const CertificateDetails = () => {
  const id = useParams().id;
  const [details, setDetails] = useState(null);
  const [notfound, setNotFound] = useState(false);
  const dispatch = useDispatch();
  const pngdownload = useRef();
  const pdfdownload = useRef();
  const user = useSelector((state) => state.user);
  const imgref = useRef();
  useEffect(() => {
    dispatch(open_loader());
    client.get("/certificate/" + id).then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        generateCertificate(
          res.data.certificate,
          res.data.owner.name,
          false,
          null,
          imgref,
          pngdownload,
          pdfdownload,
          id
        );
        setDetails({ ...res.data });
      } else setNotFound(true);
    });
  }, []);
  if (notfound) {
    return <NotFound />;
  }
  return (
    <>
      <Header />
      <div className="certificate-details">
        <h1>Certificate</h1>
        <div className="certificate">
          <img ref={imgref} alt="certificate" />
          <div className="details">
            <div className="details-xy abdd">
              <div className="abx">
                <Link to={"/users/" + details?.owner.roll}>
                  <h1>{details?.owner?.name}</h1>
                  <p>
                    ({details?.owner.dept}'{details?.owner.batch})
                  </p>
                </Link>
              </div>
            </div>
            <p className="share-this">Share this Certificate</p>
            <input
              onClick={() =>
                navigator.clipboard
                  .writeText(window.location.origin + "/certificate/" + id)
                  .then(() => {
                    dispatch(
                      open_snackbar({
                        type: "success",
                        message: "copied",
                      })
                    );
                  })
              }
              readOnly
              value={window.location.origin + "/certificate/" + id}
            />

            <div className="button-group">
              <a ref={pdfdownload}>
                <Button className="button">
                  Download as pdf{" "}
                  <svg
                    viewBox="0 0 24 24"
                    width="1em"
                    height="1em"
                    class="download-certificate-icon ui-svg-icon"
                    fill="currentColor"
                  >
                    <g>
                      <g>
                        <path d="M20.2 15.6c-.5 0-.9.4-.9.9v2.7c0 .5-.4.9-.9.9H5.6c-.5 0-.9-.4-.9-.9v-2.7c0-.5-.4-.9-.9-.9s-.9.4-.9.9v2.7c0 1.5 1.2 2.7 2.7 2.7h12.7c1.5 0 2.7-1.2 2.7-2.7v-2.7c.1-.5-.3-.9-.8-.9z"></path>
                        <path d="M11.4 17c.4.4.9.4 1.3 0l3.6-3.6c.3-.4.3-.9-.1-1.3-.3-.3-.9-.3-1.2 0l-2 2V3c0-.4-.2-.7-.5-.8-.5-.3-1.1-.2-1.4.3-.1.2-.1.3-.1.5v11.1l-2-2c-.2-.2-.4-.3-.6-.3-.5 0-.9.4-.9.9 0 .2.1.5.3.6l3.6 3.7z"></path>
                      </g>
                    </g>
                  </svg>
                </Button>
              </a>
              <a ref={pngdownload}>
                <Button className="button">
                  Download as JPEG{" "}
                  <svg
                    viewBox="0 0 24 24"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                  >
                    <g>
                      <g>
                        <path d="M20.2 15.6c-.5 0-.9.4-.9.9v2.7c0 .5-.4.9-.9.9H5.6c-.5 0-.9-.4-.9-.9v-2.7c0-.5-.4-.9-.9-.9s-.9.4-.9.9v2.7c0 1.5 1.2 2.7 2.7 2.7h12.7c1.5 0 2.7-1.2 2.7-2.7v-2.7c.1-.5-.3-.9-.8-.9z"></path>
                        <path d="M11.4 17c.4.4.9.4 1.3 0l3.6-3.6c.3-.4.3-.9-.1-1.3-.3-.3-.9-.3-1.2 0l-2 2V3c0-.4-.2-.7-.5-.8-.5-.3-1.1-.2-1.4.3-.1.2-.1.3-.1.5v11.1l-2-2c-.2-.2-.4-.3-.6-.3-.5 0-.9.4-.9.9 0 .2.1.5.3.6l3.6 3.7z"></path>
                      </g>
                    </g>
                  </svg>
                </Button>
              </a>
            </div>
            {user !== null && user.roll === details?.owner?.roll && (
              <a
                target={"_blank"}
                href="https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME"
              >
                <Button
                  className="button-linkedin"
                  style={{
                    background: "#0177B5",
                  }}
                >
                  <BsLinkedin />
                  <p>Add to profile</p>
                </Button>
              </a>
            )}

            <div className="details-xy">
              <h1>{details?.certificate?.name}</h1>
              <p>{details?.certificate?.details}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CertificateDetails;
