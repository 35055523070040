import React, { useState } from "react";
import { useEffect } from "react";
import client from "../../utils/Client";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
const Standings = ({ id }) => {
  const [ranks, setRanks] = useState([]);
  const [rows, setrows] = useState([]);
  const columns = [
    { field: "rank", headerName: "Rank", width: 100 },
    { field: "teamName", headerName: "Team Name", width: 200 },
    {
      field: "firstMember",
      headerName: "First Member",
      width: 200,
      renderCell: (value) => {
        return (
          <Link
            style={{
              color: "#202020",
              textDecoration: "none",
            }}
            to={"/users/" + value.row.roll1}
          >
            {value.value}
          </Link>
        );
      },
    },
    {
      field: "secondMember",
      headerName: "Second Member",
      width: 200,
      renderCell: (value) => {
        return (
          <Link
            style={{
              color: "#202020",
              textDecoration: "none",
            }}
            to={"/users/" + value.row.roll2}
          >
            {value.value}
          </Link>
        );
      },
    },
    {
      field: "thirdMember",
      headerName: "Third Member",
      width: 200,
      renderCell: (value) => {
        return (
          <Link
            style={{
              color: "#202020",
              textDecoration: "none",
            }}
            to={"/users/" + value.row.roll3}
          >
            {value.value}
          </Link>
        );
      },
    },
  ];
  useEffect(() => {
    client.get("/contest/standings/" + id).then((res) => {
      if (res.data.status) {
        console.log(res.data.standings);
        let temp = res.data.standings.map((item, i) => ({
          id: i,
          rank: item.rank,
          teamName: item.teamName,
          firstMember: item.members.first.name,
          secondMember: item.members.second?.name,
          thirdMember: item.members.third?.name,
          roll1: item.members.first.roll,
          roll2: item.members.second?.roll,
          roll3: item.members.third?.roll,
        }));
        temp.sort((a,b)=>{
            if(a.rank<b.rank) return -1;
            if(a.rank==b.rank) return 0;
            return 1;
        })
        setrows([...temp]);
      }
    });
  }, [id]);
  return (
    <div className="contest-details-standings">
      <h1>Final Standing</h1>
      <div
        style={{
          height: String(rows.length * 60 + 200) + "px",
          width: "100%",
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[1000]}
          paginationMode={false}
        />
      </div>
    </div>
  );
};

export default Standings;
