import React, { useEffect, useState } from "react";
import jwt from "jwt-decode";
import client from "../../utils/Client";
import "./style.scss";
import { userLogin, userLogout } from "../../redux/user/userAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import Header from "../../components/Header/Header";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import Footer from "../../components/Footer/Footer";
import { useRef } from "react";
const Login = () => {
  const hisroty = useNavigate();
  const passRef = useRef();
  const [userData, setUserData] = useState({
    roll: "",
    password: "",
  });
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (user) {
      hisroty("/users/" + user.roll);
    }
  }, []);
  const handleSubmit = () => {
    dispatch(open_loader());
    client.post("/auth/login", { ...userData }).then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        localStorage.setItem("jwtoken", res.data.jwtoken);
        dispatch(userLogin(jwt(res.data.jwtoken)));
        window.location.href =
          window.location.origin + "/users/" + jwt(res.data.jwtoken).roll;
      } else {
        dispatch(
          open_snackbar({
            message: res.data.message,
            type: "error",
          })
        );
      }
    });
  };
  const handleNextRoll = (e)=>{
    if(e.key==="Enter"){
      passRef.current.focus();
    }
  }
  const handleNextLogin = (e)=>{
    if(e.key==="Enter"){
      handleSubmit();
    }
  }

  return (
    <>
      <Header />
      <div className="login-box">
        <div className="login-midle">
          <img src="Assets/pcist.png" alt="i-mage" className="logo" />
          <div className="Login">
            <h1>Welcome Back</h1>
            <p>Enter your credentials to access your account</p>
            <input
              value={userData.roll}
              onChange={(e) =>
                setUserData({ ...userData, roll: e.target.value })
              }
              placeholder="your roll number"
              onKeyDown={handleNextRoll}
            />
            <input
              type={"password"}
              value={userData.password}
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
              placeholder="password"
              ref={passRef}
              onKeyDown={handleNextLogin}
            />
            <button onClick={handleSubmit}>Sign In</button>
          </div>

          <div className="extra-tab">
            <Link to={"/forget-password"}>forget password</Link> <span>|</span>
            <Link to={"/registration"}>request for new account</Link>
          </div>
        </div>
      </div>
      <Footer className={"login-footer"} />
    </>
  );
};

export default Login;
