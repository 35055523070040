import React, { useEffect, useState } from "react";
import client from "../../utils/Client";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
const Forgetpassword = () => {
  const hisroty = useNavigate();
  const [userData, setUserData] = useState({
    roll: "",
    newpassword: "",
    confirmpassword: "",
    verifyCode: "",
  });
  const dispatch = useDispatch();
  const [verifyBox, setVerifyBox] = useState(false);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (user) {
      hisroty("/users/" + user.roll);
    }
  }, []);
  const handleSubmit = () => {
    dispatch(open_loader());
    client
      .post("/auth/resetpassword/" + userData.roll)
      .then((res) => {
        dispatch(close_loader());
        if (res.data.status) {
          dispatch(
            open_snackbar({
              message: res.data.message,
              type: "success",
            })
          );

          setVerifyBox(true);
        } else {
          dispatch(
            open_snackbar({
              message: "wrong roll number!",
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(close_loader());
        dispatch(
          open_snackbar({
            message: "something went wrong",
            type: "error",
          })
        );
      });
  };
  const history = useNavigate();

  const handleVerifyCode = () => {
    client
      .post("/auth/verify/" + userData.roll, {
        newpassword: userData.newpassword,
        code: userData.verifyCode,
      })
      .then((res) => {
        if (res.data.status) {
          dispatch(
            open_snackbar({
              type: "success",
              message: "password changed!",
            })
          );
          hisroty("/login");
        } else {
          dispatch(
            open_snackbar({
              type: "error",
              message: res.data.message,
            })
          );
        }
      });
  };

  return (
    <>
      <Header />
      <div className="login">
        <div className="login-midle">
          <img src="Assets/pcist.png" alt="i-mage" className="logo" />
          <div className="Login">
            <h1>Welcome Back</h1>
            <p>Enter your roll number</p>
            {verifyBox === false && (
              <>
                <input
                  value={userData.roll}
                  onChange={(e) =>
                    setUserData({ ...userData, roll: e.target.value })
                  }
                  placeholder="your roll number"
                />
                <button
                  style={{
                    fontSize: 15,
                  }}
                  onClick={handleSubmit}
                >
                  Send password reset email
                </button>
              </>
            )}
            {verifyBox === true && (
              <>
                <input
                  value={userData.verifyCode}
                  onChange={(e) =>
                    setUserData({ ...userData, verifyCode: e.target.value })
                  }
                  placeholder="Verification code"
                  type={"number"}
                />
                <input
                  value={userData.newpassword}
                  onChange={(e) =>
                    setUserData({ ...userData, newpassword: e.target.value })
                  }
                  placeholder="New password"
                  type={"password"}
                />
                <input
                  value={userData.confirmpassword}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      confirmpassword: e.target.value,
                    })
                  }
                  placeholder="Confirm password"
                  type={"password"}
                />
                {verifyBox ? (
                  <button
                    style={{
                      fontSize: 15,
                    }}
                    onClick={handleVerifyCode}
                  >
                    Reset password
                  </button>
                ) : (
                  <button
                    style={{
                      fontSize: 15,
                    }}
                    onClick={handleSubmit}
                  >
                    Send password reset email
                  </button>
                )}
              </>
            )}
          </div>

          <div className="extra-tab">
            <Link to={"/login"}>Login</Link> <span>|</span>
            <Link to={"/registration"}>request for new account</Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Forgetpassword;
