import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_LOADER, OPEN_LOADER } from "../../redux/types";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import client from "../../utils/Client";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
const IssueCertificate = ({ open, setOpen, rolls }) => {
  const dispatch = useDispatch();
  let [Rolls, setRolls] = useState(rolls.join(","));
  const [certificateId, setCertificateId] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  const handleIssue = () => {
    dispatch(open_loader());
    client
      .post("/certificate/issue", { rolls: Rolls, certificateId })
      .then((res) => {
        dispatch(close_loader());
        dispatch(
          open_snackbar({
            type: res.data.status ? "success" : "error",
            message: res.data.message,
          })
        );
      });
  };
  return (
    <div className="create-certificate">
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="create-certificate-modal"
      >
        <DialogTitle id="scroll-dialog-title">Issue Certificate</DialogTitle>
        <DialogContent dividers={true}>
          {certificateId === null ? (
            <SearchCertificates setCertificateId={setCertificateId} />
          ) : (
            <div className="issue">
              <p className="p">Rolls : </p>
              <textarea
                onChange={(e) => setRolls(e.target.value)}
                value={Rolls}
              ></textarea>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (certificateId) setCertificateId(null);
              else handleClose();
            }}
          >
            Cancel
          </Button>
          {certificateId !== null && (
            <Button onClick={handleIssue}>Issue</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

const SearchCertificates = ({ certificateId, setCertificateId }) => {
  const [certificates, setCertificates] = useState([]);
  const [name, setname] = useState("");
  let cancel = null;
  const getCertificates = () => {
    if (cancel) cancel.abort();
    cancel = new AbortController();
    client
      .get("/certificate/models/" + name, {
        signal: cancel.signal,
      })
      .then((res) => {
        if (res.data.status) {
          setCertificates([...res.data.certificates]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCertificates();
  }, [name]);
  return (
    <div className="search-certificate">
      <p className="p">Certificate Name: </p>
      <input
        type={"search"}
        onChange={(e) => setname(e.target.value)}
        value={name}
      />
      {certificates.map((item, i) => (
        <div
          onClick={() => setCertificateId(item._id)}
          key={i}
          className="certificate-item-xx"
        >
          {console.log(item)}
          <img src={item.img} alt="" />
          <div className="details">
            <p>{item.name}</p>
            <div className="details-xy">
              <p>
                Name Color :{" "}
                <div
                  className="abox"
                  style={{ background: item.nameColor }}
                ></div>
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default IssueCertificate;
