import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import client from "../../utils/Client";
import getTime from "../../utils/getTime";
const months = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];
const Events = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    client.get("/events/upcoming-events").then((res) => {
      if (res.data.status) {
        let temp = res.data.events;
        if (!temp || !temp.length) return;
        temp.sort((a, b) => {
          return getTime(b.date, b.time) - getTime(a.date, a.time);
        });
        setEvents([...temp]);
      }
    });
  }, []);

  const _getDate = (date) => {
    date = new Date(date);
    return {
      mon: months[date.getMonth()],
      date: date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
    };
  };

  return (
    <div className="Events">
      <div className="title">Upcoming Events</div>
      <div className="all-events">
        {events.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              fontWeight : 'bolder',
              color : "#0000004f"
            }}
          >
            No upcoming events
          </div>
        )}
        {events.map((item, i) => (
          <div
            style={{
              cursor: "pointer",
            }}
            key={i}
            className="event-item"
            onClick={() => navigate("/event/" + item.slug)}
          >
            <div className="title-xy">
              <div className="Date">{_getDate(item.date).date}</div>
              <div className="mon-time">
                <p>{_getDate(item.date).mon}</p>
                <p>{item.time}</p>
              </div>
            </div>
            <div className="body">{item.name}</div>

            <div className="venue">
              <p>Organized by</p> Programming club of IST
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Events;
