import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import ChangePassword from "./ChangePassword";
import Contests from "./Contests";
import MembershipStatus from "./MembershipStatus";
import Menues from "./Menues";
import MyCertificates from "./MyCertificates";
import MyProfile from "./MyProfile";
import SocialLinks from "./SocialLinks";
import "./style.scss";
const UserPannel = () => {
  const roll = useParams().roll;
  const user = useSelector((state) => state.user);
  const params = useLocation();
  const urlsearchparam = new URLSearchParams(params.search);
  const activeTab = urlsearchparam.get("Tab") || "my-profile";
  if (roll !== user.roll) return Navigate("");
  return (
    <>
      <Header />
      <div className="user-pannel">
        <Menues activeTab={activeTab} />
        <div className="Tabs">
          {activeTab === "my-profile" && <MyProfile />}
          {activeTab === "membership-status" && <MembershipStatus />}
          {activeTab === "social-links" && (
            <SocialLinks
              title="Social Links"
              option={["facebook", "linkedin", "github"]}
            />
          )}
          {activeTab === "online-judges" && (
            <SocialLinks
              title="Online Judge Profiles"
              option={["codeforces", "codechef", "lightoj", "leetcode"]}
            />
          )}
          {activeTab === "change-password" && <ChangePassword />}
          {activeTab === "contests" && <Contests />}
          {activeTab === "certificates" && <MyCertificates />}
        </div>
      </div>
    </>
  );
};

export default UserPannel;
