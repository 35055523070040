import React, { useEffect, useRef } from "react";
import "./style.scss";
const CountDown = ({ className, endTime }) => {
  const hours = useRef();
  const minutes = useRef();
  const seconds = useRef();
  const days = useRef();
  let ab = null;
  useEffect(() => {
    if (ab !== null) clearInterval(ab);
    ab = setInterval(() => {
      let dist = Math.floor(endTime - new Date().getTime());
      if (dist < 0) clearInterval(ab);
      if (dist < 0) {
        if (days.current) days.current.innerHTML = "00";
        if (minutes.current) minutes.current.innerHTML = "00";
        if (seconds.current) seconds.current.innerHTML = "00";
        if (hours.current) hours.current.innerHTML = "00";
        return;
      }
      if (days.current)
        days.current.innerHTML = Math.floor(dist / (1000 * 60 * 60 * 24));
      if (hours.current)
        hours.current.innerHTML = Math.floor(
          (dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
      if (minutes.current)
        minutes.current.innerHTML = Math.floor(
          (dist % (1000 * 60 * 60)) / (1000 * 60)
        );
      if (seconds.current)
        seconds.current.innerHTML = Math.floor((dist % (1000 * 60)) / 1000);
    }, 1000);
  }, []);
  return (
    <div className={`countdown ${className}`}>
      <div ref={days} className="hours-zone">
        00
      </div>
      <p>Days</p>
      <span>:</span>
      <div ref={hours} className="hours-zone">
        00
      </div>
      <p>Hours</p>
      <span>:</span>
      <div ref={minutes} className="minutes-zone">
        00
      </div>
      <p>Minutes</p>
      <span>:</span>
      <div ref={seconds} className="second-zone">
        00
      </div>
      <p>Seconds</p>
    </div>
  );
};
export default CountDown;
