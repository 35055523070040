import MyProfile from "./MyProfile";

const options = [
  {
    name: "My Profile",
    tab: "my-profile",
    icon: (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 15C13.8 15 12 14.3 10.6 12.9C9.2 11.5 8.5 9.7 8.5 7.5C8.5 5.3 9.2 3.5 10.6 2.1C12 0.7 13.8 0 16 0C18.2 0 20 0.7 21.4 2.1C22.8 3.5 23.5 5.3 23.5 7.5C23.5 9.7 22.8 11.5 21.4 12.9C20 14.3 18.2 15 16 15ZM0 31.05V26.35C0 25.0833 0.316666 24 0.95 23.1C1.58333 22.2 2.4 21.5167 3.4 21.05C5.63333 20.05 7.775 19.3 9.825 18.8C11.875 18.3 13.9333 18.05 16 18.05C18.0667 18.05 20.1167 18.3083 22.15 18.825C24.1833 19.3417 26.3167 20.0833 28.55 21.05C29.5833 21.5167 30.4167 22.2 31.05 23.1C31.6833 24 32 25.0833 32 26.35V31.05H0Z"
          fill="#202020"
        />
      </svg>
    ),
    default: true,
  },

  {
    name: "Membership status",
    tab: "membership-status",
    icon: (
      <svg
        width="40"
        height="36"
        viewBox="0 0 40 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.9 36L17.4 27.5L19.55 25.35L25.9 31.7L37.9 19.7L40 21.9L25.9 36ZM3 8V14.95H37V8H3ZM0 3C0 2.2 0.3 1.5 0.9 0.9C1.5 0.3 2.2 0 3 0H37C37.8 0 38.5 0.3 39.1 0.9C39.7 1.5 40 2.2 40 3V14.95H38.4L25.9 27.6L19.55 21.1L13.15 27.5L14.65 29V32H3C2.2 32 1.5 31.7 0.9 31.1C0.3 30.5 0 29.8 0 29V3Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    name: "Contests",
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.65 17.85C25.3833 16.9833 24.925 16.25 24.275 15.65C23.625 15.05 22.8667 14.6167 22 14.35V0C26.7667 0.466667 30.8167 2.36667 34.15 5.7C37.4833 9.03333 39.3833 13.0833 39.85 17.85H25.65ZM17.9 39.8C12.8 39.3333 8.54167 37.2167 5.125 33.45C1.70833 29.6833 0 25.2167 0 20.05C0 14.8833 1.7 10.375 5.1 6.525C8.5 2.675 12.7667 0.5 17.9 0V14.35C16.7333 14.8167 15.7917 15.5667 15.075 16.6C14.3583 17.6333 14 18.7833 14 20.05C14 21.25 14.3583 22.3333 15.075 23.3C15.7917 24.2667 16.7333 24.9667 17.9 25.4V39.8ZM22 39.8V25.4C22.8667 25.1333 23.625 24.7083 24.275 24.125C24.925 23.5417 25.3833 22.8167 25.65 21.95H39.85C39.3833 26.7167 37.4833 30.7667 34.15 34.1C30.8167 37.4333 26.7667 39.3333 22 39.8Z"
          fill="white"
        />
      </svg>
    ),
    tab: "contests",
  },
  {
    name: "Social Links",
    icon: (
      <svg
        width="37"
        height="40"
        viewBox="0 0 37 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.35 40C28.7833 40 27.4417 39.4417 26.325 38.325C25.2083 37.2083 24.65 35.8667 24.65 34.3C24.65 34.0667 24.675 33.7917 24.725 33.475C24.775 33.1583 24.85 32.8667 24.95 32.6L9.8 23.8C9.3 24.3667 8.68333 24.825 7.95 25.175C7.21667 25.525 6.46667 25.7 5.7 25.7C4.13333 25.7 2.79167 25.1417 1.675 24.025C0.558334 22.9083 0 21.5667 0 20C0 18.4 0.558334 17.05 1.675 15.95C2.79167 14.85 4.13333 14.3 5.7 14.3C6.46667 14.3 7.2 14.45 7.9 14.75C8.6 15.05 9.23333 15.4833 9.8 16.05L24.95 7.35C24.85 7.11667 24.775 6.85 24.725 6.55C24.675 6.25 24.65 5.96667 24.65 5.7C24.65 4.1 25.2083 2.75 26.325 1.65C27.4417 0.55 28.7833 0 30.35 0C31.95 0 33.3 0.55 34.4 1.65C35.5 2.75 36.05 4.1 36.05 5.7C36.05 7.26667 35.5 8.60833 34.4 9.725C33.3 10.8417 31.95 11.4 30.35 11.4C29.5833 11.4 28.8417 11.275 28.125 11.025C27.4083 10.775 26.8 10.3667 26.3 9.8L11.15 18.2C11.2167 18.4667 11.275 18.775 11.325 19.125C11.375 19.475 11.4 19.7667 11.4 20C11.4 20.2333 11.375 20.4833 11.325 20.75C11.275 21.0167 11.2167 21.2833 11.15 21.55L26.3 30.15C26.8 29.6833 27.3833 29.3083 28.05 29.025C28.7167 28.7417 29.4833 28.6 30.35 28.6C31.95 28.6 33.3 29.15 34.4 30.25C35.5 31.35 36.05 32.7 36.05 34.3C36.05 35.8667 35.5 37.2083 34.4 38.325C33.3 39.4417 31.95 40 30.35 40Z"
          fill="white"
        />
      </svg>
    ),
    tab: "social-links",
  },
  {
    name: "Online Judge",
    tab: "online-judges",
    icon: (
      <svg
        width="44"
        height="36"
        viewBox="0 0 44 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 36C1.06667 36 0.708333 35.8583 0.425 35.575C0.141667 35.2917 0 34.9333 0 34.5C0 34.0667 0.141667 33.7083 0.425 33.425C0.708333 33.1417 1.06667 33 1.5 33H42.5C42.9333 33 43.2917 33.1417 43.575 33.425C43.8583 33.7083 44 34.0667 44 34.5C44 34.9333 43.8583 35.2917 43.575 35.575C43.2917 35.8583 42.9333 36 42.5 36H1.5ZM5 30C4.2 30 3.5 29.7 2.9 29.1C2.3 28.5 2 27.8 2 27V3C2 2.2 2.3 1.5 2.9 0.9C3.5 0.3 4.2 0 5 0H39C39.8 0 40.5 0.3 41.1 0.9C41.7 1.5 42 2.2 42 3V27C42 27.8 41.7 28.5 41.1 29.1C40.5 29.7 39.8 30 39 30H5Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    name: "Change Password",
    tab: "change-password",
    icon: (
      <svg
        width="35"
        height="40"
        viewBox="0 0 35 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5 0C15.775 0 12.8925 0.6625 10.18 1.4C7.40501 2.15 4.60751 3.0375 2.96251 3.575C2.27474 3.80213 1.66499 4.21866 1.2033 4.77675C0.741621 5.33484 0.446731 6.01184 0.352513 6.72999C-1.13749 17.9225 2.32001 26.2175 6.51501 31.705C8.29396 34.0524 10.4151 36.1196 12.8075 37.8375C13.7725 38.52 14.6675 39.0425 15.4275 39.4C16.1275 39.73 16.88 40 17.5 40C18.12 40 18.87 39.73 19.5725 39.4C20.4887 38.9547 21.3652 38.4319 22.1925 37.8375C24.585 36.1196 26.7061 34.0524 28.485 31.705C32.68 26.2175 36.1375 17.9225 34.6475 6.72999C34.5534 6.0115 34.2586 5.3341 33.797 4.77558C33.3353 4.21706 32.7255 3.80007 32.0375 3.5725C29.6495 2.78953 27.243 2.06432 24.82 1.3975C22.1075 0.664999 19.225 0 17.5 0ZM17.5 12.5C18.3856 12.4987 19.2431 12.8108 19.9206 13.3811C20.5981 13.9515 21.0519 14.7432 21.2016 15.616C21.3513 16.4889 21.1873 17.3865 20.7386 18.15C20.2899 18.9135 19.5854 19.4936 18.75 19.7875L19.7125 24.7625C19.7475 24.9434 19.7421 25.1299 19.6966 25.3085C19.6511 25.4871 19.5667 25.6534 19.4494 25.7956C19.3321 25.9377 19.1848 26.0522 19.0181 26.1307C18.8513 26.2093 18.6693 26.25 18.485 26.25H16.515C16.3309 26.2497 16.1492 26.2087 15.9827 26.1299C15.8163 26.0512 15.6693 25.9367 15.5523 25.7946C15.4353 25.6525 15.3511 25.4863 15.3057 25.3079C15.2604 25.1295 15.255 24.9432 15.29 24.7625L16.25 19.7875C15.4146 19.4936 14.7101 18.9135 14.2614 18.15C13.8127 17.3865 13.6487 16.4889 13.7984 15.616C13.9481 14.7432 14.4019 13.9515 15.0794 13.3811C15.7569 12.8108 16.6144 12.4987 17.5 12.5Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    name: "My Certificates",
    tab: "certificates",
    icon: (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        enable-background="new 0 0 64 64"
        xmlSpace="preserve"
      >
        <g id="Certificate-file">
          <path
            d="M54.1076508,14.717124c-0.0053024-0.0955-0.0237999-0.1876001-0.0559006-0.2779007
     c-0.0102005-0.0284996-0.0171013-0.0567999-0.0298004-0.0840998c-0.0480995-0.1046-0.1090012-0.2038994-0.1932983-0.2884998
     c0,0-3.4248009-3.4394999-6.8535004-6.8759999c-1.7139015-1.7188001-3.4297028-3.4356-4.7187996-4.7227001
     c-0.9531021-0.9511001-1.5810013-1.5781001-2.0234032-1.9677001c-0.006897-0.0098-0.0136986-0.0186-0.0205002-0.0284
     c-0.0009995-0.0019-0.0019989-0.0029-0.0029984-0.0048l-0.0078011,0.0058c-0.6213989-0.5415-0.8670006-0.6004-1.1478996-0.4306
     l-26.0746994,0.0009c-1.7079992,0-3.0977001,1.3907001-3.0977001,3.0996001v50.5655022
     c0,0.8661995,0.3369007,1.6805992,0.9522009,2.2967987c0.6053991,0.6026001,1.4413996,0.9482994,2.2928991,0.9482994h13.1513996
     c0.5527,0,1-0.4473,1-1c0-0.5527992-0.4473-1-1-1H13.1264496c-0.3270998,0-0.6483994-0.1338005-0.8799-0.3633003
     c-0.2323999-0.232399-0.3652-0.5536995-0.3652-0.8817978V3.1427238c0-0.6064,0.4922009-1.0996001,1.0977001-1.0996001
     l25.4626007-0.0009c0.0377007,2.2823002-0.0049019,8.6077995-0.0485992,12.7186995
     c-0.002903,0.2676001,0.1016006,0.5244007,0.2891006,0.7139006c0.1875,0.1904001,0.4442978,0.2967997,0.7108994,0.2967997
     h12.7255974v38.0997009c0,0.2851028-0.1152,0.5634003-0.3173981,0.7656021
     c-0.2041016,0.2040977-0.4756012,0.3163986-0.7645988,0.3163986h-0.455101c-0.5527,0-1,0.4472008-1,1c0,0.5527,0.4473,1,1,1
     h0.455101c0.8241997,0,1.5985985-0.3213005,2.1796989-0.9033012c0.5732002-0.5732994,0.902298-1.3671989,0.902298-2.1786995
     V14.7716236C54.1186485,14.7523241,54.1087494,14.7362242,54.1076508,14.717124z M40.4038506,13.7716236
     C40.45755,8.5080242,40.4741516,5.380024,40.4585495,3.4992237c2.3398018,2.3340001,6.6297989,6.6346998,10.2538986,10.2723999
     H40.4038506z"
          />
          <path
            d="M46.4301491,49.4289246c-0.0370979-0.1273003-0.0993004-0.2401009-0.1764984-0.3384018
     c1.4456978-1.7341003,2.3171997-3.9636993,2.3171997-6.3979988c0-5.5228004-4.4771996-10-10-10c-5.5228996,0-10,4.4771996-10,10
     c0,2.5708008,0.9783993,4.9075012,2.5725002,6.6790009L27.7680492,60.942524
     c-0.1103001,0.3779984,0.0117016,0.7862015,0.3116016,1.0419998c0.299799,0.2549019,0.7206993,0.3086014,1.0770988,0.1416016
     l3.4014015-1.6162033l2.1376991,3.0996017c0.1884003,0.2734985,0.4980011,0.4326973,0.8232002,0.4326973
     c0.0565987,0,0.1133003-0.0048981,0.169899-0.0146942c0.3848-0.0664024,0.6953011-0.3496017,0.7959023-0.7266045
     l2.2945976-8.5613976l2.2943001,8.5613976c0.1006012,0.3760033,0.410202,0.6592026,0.7938995,0.7266045
     c0.0577011,0.0097961,0.1152992,0.0146942,0.1719017,0.0146942c0.3241997,0,0.6319008-0.157299,0.8213005-0.4296989
     l2.1436005-3.0839996l3.3993988,1.5996017c0.3554001,0.1660004,0.7753983,0.1102982,1.0760994-0.1455002
     c0.2989006-0.2549019,0.4199982-0.6621017,0.3096008-1.0401001L46.4301491,49.4289246z M38.5708504,34.5973244
     c4.4636993,0,8.0951996,3.6315002,8.0951996,8.0951996c0,4.4637985-3.6315002,8.0952988-8.0951996,8.0952988
     c-4.4637985,0-8.0953007-3.6315002-8.0953007-8.0952988C30.4755497,38.2288246,34.1070518,34.5973244,38.5708504,34.5973244z
      M35.1137505,60.692524l-1.3925018-2.0185013c-0.2792969-0.4043007-0.809597-0.5478973-1.2519989-0.3359985l-2.1571999,1.024498
     l2.4841995-8.5150986c1.3003006,0.9225998,2.8286018,1.5391998,4.4862022,1.7528992L35.1137505,60.692524z M45.0913506,58.3586235
     c-0.4424019-0.2080994-0.9687996-0.0654984-1.2471008,0.3348999l-1.3964996,2.0088005l-2.1875992-8.1632004
     c1.6489983-0.2812004,3.1576996-0.9631996,4.4260979-1.9464989l2.5623016,8.781601L45.0913506,58.3586235z"
          />
          <path
            d="M38.5708504,47.692524c2.7613983,0,5-2.2384987,5-5c0-2.7613983-2.2386017-5-5-5c-2.7614021,0-5,2.2386017-5,5
     C33.5708504,45.4540253,35.8094482,47.692524,38.5708504,47.692524z M38.5708504,39.692524c1.6542015,0,3,1.3457985,3,3
     c0,1.6543007-1.3457985,3-3,3c-1.6543007,0-3-1.3456993-3-3C35.5708504,41.0383224,36.9165497,39.692524,38.5708504,39.692524z"
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    ),
  },
];

export default options;
