import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import client from "../../utils/Client";
import "./style.scss";
import Header from "../../components/Header/Header";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import { useNavigate } from "react-router-dom";
import social_links from "../../utils/social_links";
import ContestRank from "../../components/ContestRank/ContestRank";
import CertificateItem from "../../components/CertificateItem/CertificateItem";
const PublicProfile = ({ roll }) => {
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, seActiveTab] = useState("team");
  const [certificates, setCertificates] = useState([]);
  const [ranks, setRanks] = useState({ single: [], team: [] });
  useEffect(() => {
    dispatch(open_loader());
    client.get("/user/details/" + roll).then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        dispatch(open_loader());
        client.get("/team/all-teams/roll/" + roll).then((res) => {
          dispatch(close_loader());
          if (res.data.status) {
            let single = [];
            let team = [];
            res.data.teams.forEach((item) => {
              if (item.contest.type === "single") {
                single.push(item);
              } else team.push(item);
            });
            setRanks({ single: [...single], team: [...team] });
          }
        });

        setUser({ ...res.data.user });
      } else {
        navigate("/");
      }
    });
  }, []);

  useEffect(() => {
    dispatch(open_loader());
    client.get("/certificate/user/" + roll).then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        setCertificates([...res.data.certificate]);
      }
    });
  }, []);
  console.log(certificates);

  return (
    <>
      <Header />
      <div className="public-profile-view">
        <div
          style={{
            backgroundImage: `url('/Assets/black-line.svg')`,
          }}
          className="header"
        >
          <img src={user.profilePic} alt="i-mage" className="profile-picture" />
          <div className="user-det">
            <div className="user-name">{user.name}</div>
            <div className="user-position">{user.position}</div>
          </div>
        </div>

        <div className="tow-side">
          <div className="left-side">
            <div className="header-title">Profile Details</div>
            <p className="semi-title">Name</p>
            <input className="input-1" readOnly value={user.name} />
            <div className="duel-item w-75">
              <div className="duel-single-item">
                <div className="semi-title">Roll</div>
                <input
                  style={{ width: "100%" }}
                  type="text"
                  value={user.roll}
                  className="input-1"
                  readOnly
                />
              </div>
              <div className="duel-single-item">
                <div className="semi-title">Batch</div>
                <input
                  style={{ width: "100%" }}
                  type="text"
                  value={user.batch}
                  className="input-1"
                  readOnly
                />
              </div>
            </div>
            <div className="duel-item w-75">
              <div className="duel-single-item">
                <div className="semi-title">Department</div>
                <input
                  style={{ width: "100%" }}
                  type="text"
                  value={user.dept}
                  className="input-1"
                  readOnly
                />
              </div>
              <div className="duel-single-item">
                <div className="semi-title">Position</div>
                <input
                  style={{ width: "100%" }}
                  type="text"
                  value={user.position}
                  className="input-1"
                  readOnly
                />
              </div>
            </div>
            <div className="duel-item w-75">
              <div className="duel-single-item">
                <div className="semi-title">Gmail</div>
                <input
                  style={{ width: "100%" }}
                  type="text"
                  value={user.gmail}
                  className="input-1"
                  readOnly
                />
              </div>
              {user.phone && (
                <div className="duel-single-item">
                  <div className="semi-title">Phone</div>
                  <input
                    style={{ width: "100%" }}
                    type="text"
                    value={user.phone}
                    className="input-1"
                    readOnly
                  />
                </div>
              )}
            </div>
            <div className="links">
              <div className="semi-title">Social Links</div>
              {user.facebook && (
                <a target={"_blank"} href={user.facebook}>
                  {social_links.facebook.icon}
                </a>
              )}
              {user.linkedin && (
                <a target={"_blank"} href={user.linkedin}>
                  {social_links.linkedin.icon}
                </a>
              )}
              {user.github && (
                <a target={"_blank"} href={user.github}>
                  {social_links.github.icon}
                </a>
              )}
            </div>
            <div className="links">
              <div className="semi-title">Online judges</div>
              {user.codeforces && (
                <a target={"_blank"} href={user.codeforces}>
                  {social_links.codeforces.icon}
                </a>
              )}
              {user.codechef && (
                <a target={"_blank"} href={user.codechef}>
                  {social_links.codechef.icon}
                </a>
              )}
              {user.leetcode && (
                <a target={"_blank"} href={user.leetcode}>
                  {social_links.leetcode.icon}
                </a>
              )}
              {user.lightoj && (
                <a target={"_blank"} href={user.lightoj}>
                  {social_links.lightoj.icon}
                </a>
              )}
            </div>
          </div>
          <div className="right-side">
            {certificates.length > 0 && (
              <div className="abx-cc">
                <p>Certificates: </p>
                <div className="user-certificates">
                  {certificates.map((item, i) => (
                    <CertificateItem item={item} key={i} />
                  ))}
                </div>
              </div>
            )}

            <div className="header-title">Total Contest</div>
            <div className="options">
              <button
                onClick={() => {
                  seActiveTab("single");
                }}
                className={activeTab === "single" ? "active-option" : ""}
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M14 13.125C12.075 13.125 10.5 12.5125 9.275 11.2875C8.05 10.0625 7.4375 8.4875 7.4375 6.5625C7.4375 4.6375 8.05 3.0625 9.275 1.8375C10.5 0.6125 12.075 0 14 0C15.925 0 17.5 0.6125 18.725 1.8375C19.95 3.0625 20.5625 4.6375 20.5625 6.5625C20.5625 8.4875 19.95 10.0625 18.725 11.2875C17.5 12.5125 15.925 13.125 14 13.125ZM0 27.1687V23.0562C0 21.9479 0.277083 21 0.83125 20.2125C1.38542 19.425 2.1 18.8271 2.975 18.4187C4.92917 17.5437 6.80313 16.8875 8.59688 16.45C10.3906 16.0125 12.1917 15.7937 14 15.7937C15.8083 15.7937 17.6021 16.0198 19.3813 16.4719C21.1604 16.924 23.0271 17.5729 24.9812 18.4187C25.8854 18.8271 26.6146 19.425 27.1687 20.2125C27.7229 21 28 21.9479 28 23.0562V27.1687H0Z" />
                </svg>
                Solo
              </button>
              <button
                onClick={() => {
                  seActiveTab("team");
                }}
                className={activeTab === "team" ? "active-option" : ""}
              >
                <svg
                  width="38"
                  height="27"
                  viewBox="0 0 38 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 27V22.913C0 21.8986 0.257919 20.9783 0.773756 20.1522C1.28959 19.3261 2.00603 18.7101 2.92308 18.3043C5.01508 17.3768 6.89932 16.7101 8.57579 16.3043C10.2523 15.8986 11.9789 15.6957 13.7557 15.6957C15.5324 15.6957 17.2519 15.8986 18.914 16.3043C20.5762 16.7101 22.4532 17.3768 24.5453 18.3043C25.4623 18.7101 26.1859 19.3261 26.7161 20.1522C27.2462 20.9783 27.5113 21.8986 27.5113 22.913V27H0ZM30.0905 27V22.913C30.0905 21.087 29.632 19.587 28.7149 18.413C27.7979 17.2391 26.5943 16.2899 25.1041 15.5652C27.0815 15.7971 28.9442 16.1377 30.6923 16.587C32.4404 17.0362 33.859 17.5507 34.948 18.1304C35.8937 18.6812 36.6388 19.3623 37.1833 20.1739C37.7278 20.9855 38 21.8986 38 22.913V27H30.0905ZM13.7557 13.0435C11.8643 13.0435 10.3167 12.4348 9.11312 11.2174C7.9095 10 7.30769 8.43478 7.30769 6.52174C7.30769 4.6087 7.9095 3.04348 9.11312 1.82609C10.3167 0.608696 11.8643 0 13.7557 0C15.6471 0 17.1946 0.608696 18.3982 1.82609C19.6018 3.04348 20.2036 4.6087 20.2036 6.52174C20.2036 8.43478 19.6018 10 18.3982 11.2174C17.1946 12.4348 15.6471 13.0435 13.7557 13.0435ZM29.2308 6.52174C29.2308 8.43478 28.629 10 27.4253 11.2174C26.2217 12.4348 24.6742 13.0435 22.7828 13.0435C22.4676 13.0435 22.1165 13.0217 21.7296 12.9783C21.3428 12.9348 20.9917 12.8551 20.6765 12.7391C21.3643 12.0145 21.8873 11.1232 22.2455 10.0652C22.6037 9.00725 22.7828 7.82609 22.7828 6.52174C22.7828 5.21739 22.6037 4.06522 22.2455 3.06522C21.8873 2.06522 21.3643 1.14493 20.6765 0.304348C20.9917 0.217392 21.3428 0.144928 21.7296 0.0869569C22.1165 0.0289858 22.4676 0 22.7828 0C24.6742 0 26.2217 0.608696 27.4253 1.82609C28.629 3.04348 29.2308 4.6087 29.2308 6.52174Z" />
                </svg>
                Team
              </button>
            </div>
            {ranks[activeTab]?.map((item) => (
              <ContestRank details={item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicProfile;
