import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./components/Loader/Loader";
import "./App.scss";
import { Snackbar, Alert } from "@mui/material";
import { close_snackbar } from "./redux/SnackBar/SnackBarActions";
import client from "./utils/Client";
import { userLogin } from "./redux/user/userAction";
import { close_loader, open_loader } from "./redux/Loader/LoaderActions";
const App = ({ children }) => {
  const _open_loader = useSelector((state) => state.loader);
  const snackbar = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(open_loader());
    client.get("/user/me").then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        dispatch(userLogin(res.data.user));
      }
    });
  }, []);
  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => dispatch(close_snackbar())}
      >
        <Alert
          onClose={() => dispatch(close_snackbar())}
          severity={snackbar.type}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {_open_loader && <Loader />}
      {children}
    </>
  );
};

export default App;
