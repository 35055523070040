import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userLogout } from "../../redux/user/userAction";
import ScrollToTop from "../../utils/ScrollToTop";
import "./style.scss";

const Header = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const buttonRef = useRef();
  const tab = useRef();
  const dropdown = useRef();
  const options = [
    {
      text: "Home",
      path: "/",
    },
    {
      text: "About us",
      path: "/about-us",
    },
    {
      text: "Events",
      path: "/events",
    },
    {
      text: "contests",
      path: "/contests",
    },
    {
      text: "Certificate",
      path: "/certificate",
    },
    {
      text: "Courses",
      path: "/courses",
    },
  ];
  const handleToggle = () => {
    if (tab.current?.classList.contains("open-slider")) {
      tab.current?.classList.remove("open-slider");
    } else tab.current?.classList.add("open-slider");
  };
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (
        tab.current &&
        !tab.current.contains(e.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        if (tab.current?.classList.contains("open-slider")) {
          tab.current?.classList.remove("open-slider");
        }
      }
    });

    document.addEventListener("click", (e) => {
      if (dropdown.current && !dropdown.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    });
  }, []);
  return (
    <>
      <ScrollToTop />
      <div ref={buttonRef} onClick={handleToggle} className="menu-open-button">
        <div className="container">
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>
      <div ref={tab} className="Header">
        <div className="left-side">
          <img
            onClick={() => navigate("/")}
            src="/Assets/pcist.svg"
            alt="i-mage"
            className="logo"
          />
          <p
            onClick={() => navigate("/users/" + user.roll)}
            className="mobile-name"
          >
            {user?.name}
          </p>
          {user !== null && user.role === "admin" && (
            <p
              style={{
                marginTop: 0,
              }}
              onClick={() => navigate("/admin/")}
              className="mobile-name"
            >
              {"admin pannel"}
            </p>
          )}
          <div className="menu-items">
            {options.map((item, i) => (
              <Link key={i} to={item.path} className="item">
                {item.text}
              </Link>
            ))}
            {user ? (
              <div
                onClick={() => dispatch(userLogout())}
                className="item show-mobile"
              >
                Logout
              </div>
            ) : (
              <div className="item show-mobile">
                <Link to={"/login"}>Login</Link>
              </div>
            )}
          </div>
        </div>
        <div ref={dropdown} className="user-options">
          {user ? (
            <div className="profile">
              <img src={user.profilePic} alt="i-mage" className="profile-pic" />
              <div
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="drop-down"
              >
                <p>{user.name}</p>
                <button className="open-dropdown">
                  <svg
                    viewBox="0 0 20 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 9.95L0 0H20L10 9.95Z" fill="#202020" />
                  </svg>
                </button>

                {dropdownOpen && (
                  <div className="drop-down-items">
                    {user.role === "admin" && (
                      <div
                        onClick={() => navigate("/admin")}
                        className="dr-item"
                      >
                        Admin pannel
                      </div>
                    )}
                    <div
                      onClick={() => navigate("/users/" + user.roll)}
                      className="dr-item"
                    >
                      Profile
                    </div>
                    <div
                      onClick={() => dispatch(userLogout())}
                      className="dr-item"
                    >
                      Logout
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="auth">
              <Link to={"/login"}>Login</Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
