import ordinal from "ordinal";
import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
const ContestRank = ({ details }) => {
  return (
    <Link
      style={{ textDecoration: "none", color: "#202020" }}
      to={`/contest/${details.contest.slug}`}
    >
      <div className="contest-rank">
        <div className="tow-sided">
          <div className="left-side">
            {" "}
            <h1>{details.contest.name}</h1>
            <p>{details.teamName}</p>
          </div>

          <div className="right-sided">
            <div className="rank-svg">
              <svg
                width="67"
                height="60"
                viewBox="0 0 67 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M63.6941 7.40489H51.6938V2.77683C51.6938 1.23801 50.4591 0 48.9245 0H17.539C16.0043 0 14.7696 1.23801 14.7696 2.77683V7.40489H2.76931C1.23465 7.40489 0 8.6429 0 10.1817V16.661C0 20.7915 2.59623 25.0378 7.14251 28.3121C10.7772 30.9386 15.1966 32.6047 19.8352 33.1369C23.4584 39.1649 27.6931 41.6525 27.6931 41.6525V49.983H22.1545C18.0813 49.983 14.7696 52.378 14.7696 56.4623V57.8507C14.7696 58.6143 15.3927 59.2391 16.1543 59.2391H50.3091C51.0707 59.2391 51.6938 58.6143 51.6938 57.8507V56.4623C51.6938 52.378 48.3821 49.983 44.3089 49.983H38.7703V41.6525C38.7703 41.6525 43.0051 39.1649 46.6282 33.1369C51.2784 32.6047 55.6977 30.9386 59.3209 28.3121C63.8557 25.0378 66.4634 20.7915 66.4634 16.661V10.1817C66.4634 8.6429 65.2288 7.40489 63.6941 7.40489ZM11.458 22.3072C8.64255 20.2709 7.38482 18.0031 7.38482 16.661V14.8098H14.7927C14.9081 18.5816 15.462 21.8907 16.2697 24.7832C14.5273 24.1816 12.9004 23.3486 11.458 22.3072ZM59.0786 16.661C59.0786 18.5238 57.0362 20.8378 55.0054 22.3072C53.5631 23.3486 51.9245 24.1816 50.1822 24.7832C50.9899 21.8907 51.5438 18.5816 51.6592 14.8098H59.0786V16.661Z"
                  fill="#202020"
                />
              </svg>
              <p>Rank</p>
            </div>
            <div className="rank">{ordinal(details.rank)}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ContestRank;
