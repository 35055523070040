import { createBrowserRouter } from "react-router-dom";
import Aboutus from "./routes/Aboutus/Aboutus";
import AdminPannel from "./routes/AdminPannel/AdminPannel";
import AllTeams from "./routes/AllTeams/AllTeams";
import CertificateDetails from "./routes/CertificateDetails/CertificateDetails";
import Certificates from "./routes/Certificates/Certificates";
import Contest from "./routes/Contest/Contest";
import ContestDetails from "./routes/ContestDetails/ContestDetails";
import Contributors from "./routes/Contributors/Contributors";
import CourseDetails from "./routes/CourseDetails/CourseDetails";
import Courses from "./routes/Courses/Courses";
import Events from "./routes/Event/Events";
import EventDetails from "./routes/EventDetails/EventDetails";
import Forgetpassword from "./routes/ForgetPassword/Forgetpassword";
import Home from "./routes/Home/Home";
import Login from "./routes/Login/Login";
import NotFound from "./routes/NotFound/NotFound";
import Registration from "./routes/Registration/Registration";
import User from "./routes/user/User";
import { AuthenticatedRouteForAdmin } from "./utils/AuthenticateRoutes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/registration",
    element: <Registration />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forget-password",
    element: <Forgetpassword />,
  },
  {
    path: "/users/:roll",
    element: <User />,
  },
  {
    path: "/contests",
    element: <Contest />,
  },
  {
    path: "/admin",
    element: <AuthenticatedRouteForAdmin component={<AdminPannel />} />,
  },
  {
    path: "/events",
    element: <Events />,
  },
  {
    path: "/contest/:slug",
    element: <ContestDetails />,
  },
  {
    path: "/contest/:slug/all-teams",
    element: <AuthenticatedRouteForAdmin component={<AllTeams />} />,
  },
  {
    path: "/event/:slug",
    element: <EventDetails />,
  },
  {
    path: "/about-us",
    element: <Aboutus />,
  },
  {
    path: "/certificate",
    element: <Certificates />,
  },
  {
    path: "/certificate/:id",
    element: <CertificateDetails />,
  },
  {
    path: "/courses",
    element: <Courses />,
  },
  {
    path: "/course/:slug",
    element: <CourseDetails />,
  },
  {
    path: "/contributors",
    element: <Contributors />,
  },
  {
    path: "/*",
    element: <NotFound />,
  },
]);

export default router;
