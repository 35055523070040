import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import client from "../../utils/Client";
const MembershipStatus = () => {
  const roll = useParams().roll;
  const dispatch = useDispatch();
  const [remainingDays, setRemainingDays] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  useEffect(() => {
    dispatch(open_loader());
    client.get("/user/details/" + roll).then((res) => {
      if (res.data.status) {
        dispatch(close_loader());
        const membership = res.data.user.memberShipEndTime;
        const today = Date.now();
        let remaining = membership - today;
        if (remaining <= 0) {
          setIsFinished(true);
          return;
        }
        remaining = remaining / 1000;
        remaining = Math.floor(remaining / (60 * 60 * 24));
        setRemainingDays(remaining);
      }
    });
  }, []);
  return (
    <div className="membership-status">
      <div className="title">Membership Status</div>
      <div className="box">
        <div className="remining-days">
          <h1>{remainingDays ? remainingDays : 0}</h1>
          <h2>Days</h2>
        </div>
        <CircularProgressbar
          strokeWidth={15}
          className="circuler-box"
          value={isFinished ? 0 : remainingDays}
        ></CircularProgressbar>
        <div className="details-box">
          <div className="semi-title">Membership Type-Status</div>
          {isFinished ? (
            <div style={{ color: "#d83333" }}>
              your membership is finished!
              <br />
              please renew
            </div>
          ) : (
            <div className="success-font-color">active</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MembershipStatus;
