import React from "react";
import Nav from "./Nav";
import "./style.scss";
import { useLocation } from "react-router-dom";
import CreateContest from "./CreateContest";
import UpdateStandings from "./UpdateStandings";
import ApproveNewUser from "./ApproveNewUser";
import AllMembers from "./AllMembers";
import UpdateGallery from "./UpdateGallery";
import CreateEvent from "./CreateEvent";
import UpdateMemberShip from "./UpdateMemberShip";
import Header from "../../components/Header/Header";
import Achievements from "./Achievements";
import EditContest from "./EditContest";
const AdminPannel = () => {
  const params = useLocation();
  const urlsearchparam = new URLSearchParams(params.search);
  const activeTab = urlsearchparam.get("Tab") || "create-new-contest";
  const [contestSlug, setContestSlug] = React.useState(
    urlsearchparam.get("contest-slug")
  );

  return (
    <>
      <Header />
      <div className="Admin-pannel">
        <Nav activeTab={activeTab} />
        <div className="Tabs">
          {activeTab === "create-new-contest" ? (
            <CreateContest />
          ) : activeTab === "update-contest-standing" ? (
            <UpdateStandings
              contestSlug={contestSlug}
              setContestSlug={setContestSlug}
            />
          ) : activeTab === "approve-new-user" ? (
            <ApproveNewUser />
          ) : activeTab === "all-members" ? (
            <AllMembers />
          ) : activeTab === "update-gallery" ? (
            <UpdateGallery />
          ) : activeTab === "create-event" ? (
            <CreateEvent />
          ) : activeTab === "update-membership" ? (
            <UpdateMemberShip />
          ) : activeTab === "achivements" ? (
            <Achievements />
          ) : activeTab === "edit-contest" ? (
            <EditContest slug={contestSlug} setSlug={setContestSlug} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AdminPannel;
