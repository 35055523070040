import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateCertificate from "../../components/CreateCertificate/CreateCertificate";
import IssueCertificate from "../../components/IssueCertificate/IssueCertificate";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { BiSearchAlt2 } from "react-icons/bi";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import "./style.scss";
import { useEffect } from "react";
import client from "../../utils/Client";
import generateCertificate from "../../utils/htmltoimg";
import { Button } from "@mui/material";
const Certificates = () => {
  const [open, setOpen] = useState({
    issue: false,
    create: false,
  });
  const user = useSelector((state) => state.user);
  const [cerid, setcerid] = useState("");
  let [certificate, setCertificate] = useState(null);
  const [owner, setOwner] = useState(null);
  const pdfdownload = useRef();
  const pngdownload = useRef();
  const ref = useRef();
  let cancel = null;
  const dispatch = useDispatch();
  useEffect(() => {
    if (cancel) {
      cancel.abort();
    }
    cancel = new AbortController();
    if (cerid && cerid.length) {
      client
        .get("/certificate/" + cerid, {
          signal: cancel.signal,
        })
        .then((res) => {
          if (res.data.status) {
            generateCertificate(
              res.data.certificate,
              res.data.owner.name,
              false,
              null,
              ref,
              pngdownload,
              pdfdownload,
              cerid
            );
            setCertificate(res.data.certificate);
            setOwner(res.data.owner);
          } else setCertificate(null);
        });
    } else setCertificate(null);
  }, [cerid]);
  return (
    <>
      <Header />
      <CreateCertificate
        open={open.create}
        setOpen={() => setOpen({ ...open, create: false })}
      />
      <IssueCertificate
        open={open.issue}
        setOpen={() => setOpen({ ...open, issue: false })}
        rolls={[]}
      />

      <div className="certificates">
        {user !== null && user.role === "admin" && (
          <div className="add-buttons">
            <Button
              onClick={() => {
                setOpen({ issue: false, create: true });
              }}
              className="buttons"
            >
              Create New Certificate Model
            </Button>
            <Button
              onClick={() => {
                setOpen({ issue: true, create: false });
              }}
              className="buttons"
            >
              Issue Certificate
            </Button>
          </div>
        )}
        <div className="search-box">
          <BiSearchAlt2 />
          <input
            value={cerid}
            onChange={(e) => setcerid(e.target.value)}
            placeholder="search with certificate id ex: (PCISTXXXXXXXXXXX)"
          />
        </div>
        <div
          style={{
            display: certificate !== null ? null : "none",
          }}
          className="certi-det"
        >
          <img ref={ref} alt={"certificate"} />
          <div className="left-texts">
            <p className="p">Share this certificate</p>
            <input
              onClick={() =>
                navigator.clipboard
                  .writeText(window.location.origin + "/certificate/" + cerid)
                  .then(() => {
                    dispatch(
                      open_snackbar({
                        type: "success",
                        message: "copied",
                      })
                    );
                  })
              }
              readOnly
              value={window.location.origin + "/certificate/" + cerid}
            />
            <div className="button-group">
              <a ref={pdfdownload}>
                <Button
                  style={{
                    marginTop: 20,
                  }}
                  className="button"
                >
                  Download as pdf
                </Button>
              </a>
              <a ref={pngdownload}>
                <Button
                  style={{
                    marginTop: 20,
                    marginLeft: 10,
                  }}
                  className="button"
                >
                  Download as JPEG
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Certificates;
