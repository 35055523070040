import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { AiFillCloseCircle } from "react-icons/ai";
import DialogTitle from "@mui/material/DialogTitle";
import RichTextEditorPro from "../../components/RitchTextEditorPro/RitchTextEditorPro";
import FileUpload from "../../components/FileUpload/FileUpload";
import client from "../../utils/Client";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
const AddNewCourse = ({
  fetchCourses,
  update,
  slug,
  item,
  editOpen,
  setEditOpen,
}) => {
  const user = useSelector((state) => state.user);
  const [details, setDetails] = useState({
    finished: false,
    file: null,
    details: "",
    name: "",
    reqMembership: false,
    finished: false,
    running: false,
    banner: null,
    fee: 0,
  });
  const [open, setOpen] = useState(false);
  const [technology, setTechnologies] = useState([]);
  const [techin, settechin] = useState("");
  const handleClose = () => {
    setDetails({});
    if (update) {
      setEditOpen(false);
    }
    setOpen(false);
  };

  const handleTechnologyInput = (e) => {
    if (e.key === "Enter") {
      if (techin.length === 0) return;
      if (technology.indexOf(techin) !== -1) return;
      setTechnologies([...technology, techin]);
      settechin("");
    }
  };

  const removeTech = async (id) => {
    let temp = technology;
    temp.splice(id, 1);
    console.log(temp);
    setTechnologies([...temp]);
  };
  const dispatch = useDispatch();

  const handleAddNew = () => {
    details.technology = technology.map((item, key) => ({
      i: key,
      value: item,
    }));
    details.technology = JSON.stringify(details.technology);
    let formdata = new FormData();
    Object.keys(details).forEach((item) => {
      if (item !== "banner") {
        formdata.append(item, details[item]);
      }
    });
    dispatch(open_loader());
    client.post("/courses/", formdata).then((res) => {
      dispatch(close_loader());
      fetchCourses();
      dispatch(
        open_snackbar({
          type: res.data.status ? "success" : "error",
          message: res.data.message,
        })
      );
      if (res.data.status) {
        handleClose();
      }
    });
  };
  const handleUpdate = () => {
    details.technology = technology.map((item, key) => ({
      i: key,
      value: item,
    }));
    details.technology = JSON.stringify(details.technology);
    let formdata = new FormData();
    Object.keys(details).forEach((item) => {
      if (item !== "banner") {
        formdata.append(item, details[item]);
      }
    });
    dispatch(open_loader());
    client.put("/courses/" + item._id, formdata).then((res) => {
      dispatch(close_loader());
        if (res.data.status) {
          fetchCourses();
          handleClose();
        }
        dispatch(
          open_snackbar({
            type: res.data.status ? "success" : "error",
            message: res.data.message,
          })
        );
    });
  };

  const handleSubmit = () => {
    if (update) {
      handleUpdate();
    } else handleAddNew();
  };

  useEffect(() => {
    if (update && editOpen) {
      if (editOpen) setOpen(true);
      else setOpen(false);
      setDetails({ ...item, banner: item.banner });
      setTechnologies([...item.technology]);
    }
  }, [editOpen]);
  return (
    <>
      {!update && user && user.role === "admin" && (
        <button onClick={() => setOpen(true)}>Add New course</button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="add-new-course-modal"
      >
        <DialogTitle id="scroll-dialog-title">Add New Course</DialogTitle>
        <DialogContent className="content" dividers={true}>
          <p>Course Name: </p>
          <input
            value={details.name}
            onChange={(e) => setDetails({ ...details, name: e.target.value })}
            placeholder="course name"
          />
          <p>Course Details: </p>
          <RichTextEditorPro
            value={details.details}
            setValue={(e) => setDetails({ ...details, details: e })}
          />
          <p style={{ marginTop: 20 }}></p>
          {/* <input  type={"date"} /> */}
          <p>Technologies : </p>
          <div className="technologies">
            {technology.map((item, i) => (
              <div key={i} className="tech">
                <p>{item}</p>
                <button onClick={() => removeTech(i)}>
                  <AiFillCloseCircle />
                </button>
              </div>
            ))}
          </div>
          <input
            style={{
              marginTop: 10,
            }}
            placeholder="Press enter to add"
            onKeyDown={(e) => handleTechnologyInput(e)}
            onChange={(e) => settechin(e.target.value)}
            value={techin}
          />

          <p>Fee: </p>
          <input
            value={details.fee}
            onChange={(e) => setDetails({ ...details, fee: e.target.value })}
            type={"number"}
            placeholder="fee"
          />
          <div className="tow-sided">
            <div className="axx">
              <p>Membership: </p>
              <input
                checked={details.reqMembership}
                onChange={(e) => {
                  setDetails({ ...details, reqMembership: e.target.checked });
                }}
                type={"checkbox"}
              />
            </div>
            <div className="axx">
              <p>Running: </p>
              <input
                onChange={(e) =>
                  setDetails({ ...details, running: e.target.checked })
                }
                checked={details.running}
                type={"checkbox"}
              />
            </div>
            <div className="axx">
              <p>Finished: </p>
              <input
                onChange={(e) =>
                  setDetails({ ...details, finished: e.target.checked })
                }
                checked={details.finished}
                type={"checkbox"}
              />
            </div>
          </div>
          <p>Banner: </p>
          {details.banner !== null &&
            details.banner !== undefined &&
            details.banner.length > 0 && <img src={details.banner} />}
          <FileUpload
            handleChange={(e) => {
              setDetails({
                ...details,
                file: e,
                banner: URL.createObjectURL(e),
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNewCourse;
