import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import client from "../../utils/Client";
import getTime from "../../utils/getTime";
import { BiDotsVerticalRounded } from "react-icons/bi";
import "./style.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const Events = () => {
  const [contestsData, setContestsData] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [open_d, setopen_d] = useState(false);
  const [openDropDow, setOpenDropDown] = useState(null);
  const [openForDelete, setOpenForDelete] = useState(null);
  const navigate = useNavigate();
  const fetchEvents = async () => {
    dispatch(open_loader());
    client.get("/events").then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        console.log(res.data);
        let temp = res.data.events;
        if (!temp || !temp.length) return;
        temp.sort((a, b) => {
          return getTime(b.date, b.time) - getTime(a.date, a.time);
        });
        setContestsData([...temp]);
      }
    });
  };
  useEffect(() => {
    fetchEvents();
  }, []);

  const _getDate = (date) => {
    date = new Date(date);
    return {
      mon: months[date.getMonth()],
      date: date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
    };
  };
  const deleteContest = () => {
    dispatch(open_loader());
    setopen_d(false);
    client.delete("/events/" + openForDelete).then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        let temp = contestsData;
        temp = temp.filter((item) => item._id !== openForDelete);
        setContestsData([...temp]);
        dispatch(
          open_snackbar({
            type: "success",
            message: "Event deleted",
          })
        );
      } else {
        dispatch(
          open_snackbar({
            type: "error",
            message: res.data.status,
          })
        );
      }
    });
  };
  const updateFinished = (id, i) => {
    dispatch(open_loader());
    client.post("/events/" + id + "/finished").then((res) => {
      dispatch(close_loader());
      if (res.data.status) {
        let temp = contestsData;
        temp[i].finished = !temp[i].finished;
        setContestsData([...temp]);
      }
      dispatch(
        open_snackbar({
          type: res.data.status ? "success" : "error",
          message: res.data.message,
        })
      );
    });
  };
  return (
    <>
      <Header />
      <Dialog
        open={open_d}
        onClose={() => setopen_d(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this item?"}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={() => setopen_d(false)}>Cancel</Button>
          <Button onClick={deleteContest} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div className="contest">
        <h1>Events</h1>
        <div className="all-contests">
          {contestsData.length===0 && <p className="empty-div">No Event is available now</p>}
          {contestsData.map((item, key) => (
            <div key={key} className="single-contest">
              <div
                style={{
                  backgroundImage: `url(/Assets/black-line-long.svg)`,
                }}
                className="date"
              >
                <div className="dat">{_getDate(item.date).date}</div>
                <div className="mon">{_getDate(item.date).mon}</div>
              </div>
              <div className="banner">
                <img
                  src={
                    item.banner
                      ? item && item.banner && item.banner[0] === "/"
                        ? item.banner.slice(1)
                        : item.banner
                      : "/Assets/default.event.svg"
                  }
                  alt="i-mage"
                />
              </div>
              <div className="event-details">
                <div className="name">{item.name}</div>
                <div className="times">
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.7431 24.6557L24.6141 22.7846L18.0032 16.1322V7.77505H15.5085V17.1301L22.7431 24.6557ZM16.6311 33.2623C14.3582 33.2623 12.21 32.8257 10.1866 31.9526C8.16311 31.0794 6.39606 29.8875 4.88539 28.3769C3.37473 26.8662 2.18284 25.0991 1.3097 23.0757C0.436567 21.0522 0 18.9041 0 16.6311C0 14.3582 0.436567 12.21 1.3097 10.1866C2.18284 8.16311 3.37473 6.39606 4.88539 4.88539C6.39606 3.37473 8.16311 2.18284 10.1866 1.3097C12.21 0.436567 14.3582 0 16.6311 0C18.9041 0 21.0522 0.436567 23.0757 1.3097C25.0991 2.18284 26.8662 3.37473 28.3769 4.88539C29.8875 6.39606 31.0794 8.16311 31.9526 10.1866C32.8257 12.21 33.2623 14.3582 33.2623 16.6311C33.2623 18.9041 32.8257 21.0522 31.9526 23.0757C31.0794 25.0991 29.8875 26.8662 28.3769 28.3769C26.8662 29.8875 25.0991 31.0794 23.0757 31.9526C21.0522 32.8257 18.9041 33.2623 16.6311 33.2623Z"
                      fill="#202020"
                    />
                  </svg>
                  <div className="time">{item.time}</div>
                </div>
              </div>
              <button
                onClick={() => navigate("/event/" + item.slug)}
                className="see-details"
              >
                see details
              </button>
              {item.finished === true && (
                <div className="finished-tag">Finished</div>
              )}
              {user?.role === "admin" && (
                <>
                  <button
                    onClick={() =>
                      setOpenDropDown(openDropDow === key ? null : key)
                    }
                    className="see-details menu"
                  >
                    <BiDotsVerticalRounded />
                  </button>
                  {openDropDow === key && (
                    <EditDeleteDropDown
                      id={item._id}
                      setopen_d={setopen_d}
                      setOpenForDelete={setOpenForDelete}
                      setOpenDrop={setOpenDropDown}
                      finished={item.finished}
                      updateFinished={() => updateFinished(item._id, key)}
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Events;

const EditDeleteDropDown = ({
  id,
  setOpenForDelete,
  setopen_d,
  setOpenDrop,
  finished,
  updateFinished,
}) => {
  return (
    <div style={{ height: "60px" }} className="edit-delete drop-down">
      <div
        onClick={() => {
          setOpenForDelete(id);
          setopen_d(true);
          setOpenDrop(null);
        }}
        className="delete"
      >
        Delete
      </div>
      <div
        onClick={updateFinished}
        className="delete"
        style={{
          fontSize: 14,
        }}
      >
        finished
        <div className={`${finished ? "finished" : "not-finished"}`}></div>
      </div>
    </div>
  );
};
