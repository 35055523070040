import React, { useState } from "react";
import { useEffect } from "react";
import { BsFillTrashFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import FileUpload from "../../components/FileUpload/FileUpload";
import UpdateModal from "../../components/UpdateModal/UpdateModal";
import { close_loader, open_loader } from "../../redux/Loader/LoaderActions";
import { open_snackbar } from "../../redux/SnackBar/SnackBarActions";
import client from "../../utils/Client";
import { BACKEND_URL } from "../../utils/config";
const LandingPageImageUpdate = ({open,setOpen, fetchImage, images}) => {
  const dispatch = useDispatch();
 
  const handleImageUpload = (e) => {
    dispatch(open_loader());
    let formdata = new FormData();
    formdata.append("file", e);
    client
      .post("/landingpageimages", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch(close_loader());
        if (res.data.status) fetchImage();
        dispatch(
          open_snackbar({
            type: res.data.status ? "success" : "error",
            message: res.data.message,
          })
        );
      });
  };
  useEffect(() => {
    fetchImage();
  }, []);
  const deleteImage = (id) => {
    dispatch(open_loader());
    client.delete("/landingpageimages/" + id).then((res) => {
      dispatch(close_loader());
      if (res.data.status) fetchImage();
      dispatch(
        open_snackbar({
          type: res.data.status ? "success" : "error",
          message: res.data.message,
        })
      );
    });
  };
  return (
    <UpdateModal
      title="Landing Page Images"
      className={"landing-page-image-upload"}
      open={open}
      setOpen={setOpen}
    >
      {images.map((item, i) => (
        <div className="image-item" key={i}>
          <img src={ item.path} />
          <button onClick={() => deleteImage(item._id)}>
            <BsFillTrashFill />
          </button>
        </div>
      ))}
      <FileUpload handleChange={handleImageUpload} />
    </UpdateModal>
  );
};

export default LandingPageImageUpdate;
